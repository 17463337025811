import type { AxiosError, AxiosResponse } from "axios";
import axios, { type InternalAxiosRequestConfig } from "axios";
import {
  API_HOST,
  AWS_CDN_DATA_URL,
  AWS_S3_DATA_STORAGE,
  ENVIRONMENT,
  FILE_STORAGE_API_URL,
} from "@/constants/envs";
import {
  getValidatedAccessToken,
  getValidatedRefreshToken,
  goSignInPage,
} from "@/utils/commands";
import dayjs from "dayjs";
import jwt_decode from "jwt-decode";
import { useUserStore } from "@/stores/user";
import { useAlertStore } from "@/stores/alert";
import timezone from "dayjs/plugin/timezone";
import { stringify } from "qs";
import type {
  PresignedUrlParam,
  S3Upload,
  S3UploadUrlParam,
  UploadParameters,
} from "@/definitions/types";
import { AclType, ProjectType } from "@/definitions/selections";
import type { Files } from "@/definitions/models";
import { createApp, h, type Component } from "vue-demi";
import html2pdf from "html2pdf.js";
import { filterFileName } from "@/utils/files";

export interface ApiErrorResult {
  detail: string;
}

export const axiosInstance = axios.create({
  baseURL: `${API_HOST}api/`,
  headers: {
    "Content-Type": "application/json;charset=UTF-8",
    "X-Profile-Id": 0,
  },
});

axiosInstance.interceptors.request.use(
  async function (config) {
    const excludedUrls = ["api/v1/users/sign-in"];

    if (excludedUrls.some((url) => config.url?.includes(url))) {
      return config;
    }

    if (!config.headers) {
      console.error("Failed to set 'request headers' : headers is not exist");
      return {} as InternalAxiosRequestConfig;
    }

    config.headers.Authorization = `Bearer ${await getValidatedAccessToken()}`;
    const addProfileIdUrl = ["api/v1/quotations"] || ["api/v1/bookings"];
    if (addProfileIdUrl) {
      config.headers["X-Profile-Id"] =
        window.localStorage.getItem("X-Profile-Id");
    }
    return config;
  },
  function (error) {
    toastAxiosError(error);
    return Promise.reject(error);
  },
);

axiosInstance.interceptors.response.use(
  function (response) {
    if (response?.status?.toString().startsWith("2")) {
      return response;
    } else {
      console.error(response);
      return response;
    }
  },
  async function (error: AxiosError) {
    console.error(error.message);
    if (error.message === "Network Error") {
      const { toastError } = useAlertStore();
      toastError("Service Unavailable");
      return;
    }
    if (error.response) {
      if (error.response.headers.refreshtoken === "must") {
        const refreshToken = await apiRefreshToken(error);
        if (refreshToken?.config?.headers) {
          refreshToken.config.headers.Authorization =
            await getValidatedAccessToken();
          return axiosInstance.request(refreshToken.config);
        } else {
          return;
        }
      }
      if ([400, 401, 403, 404, 500].includes(error.response.status)) {
        if (error.response.status === 403) {
          const { toastWarning } = useAlertStore();
          toastWarning(getErrorMessage(error));
        } else if ([401].includes(error.response.status)) {
          await goSignInPage();
        }
        throw error;
      }
    }
    if (ENVIRONMENT === "local") {
      toastAxiosError(error);
    }
    console.error(error);
    return Promise.reject(error);
  },
);

export async function getApi<T = never, R = T>(url: string): Promise<R | null> {
  try {
    return (await axiosInstance.get<T, AxiosResponse<R>>(url)).data;
  } catch (e) {
    console.error(e);
    console.warn("No data returned from server");
    return null;
  }
}

export async function postApi<T = never, R = T>(
  url: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/explicit-module-boundary-types
  data: any,
  alertError = true,
): Promise<R | null> {
  try {
    return (await axiosInstance.post<T, AxiosResponse<R>>(url, data)).data;
  } catch (e) {
    console.error(e);
    if (alertError) {
      toastAxiosError(e);
    }
    return null;
  }
}

export async function putApi<T = never, R = T>(
  url: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/explicit-module-boundary-types
  data: any,
  alertError = true,
): Promise<R | null> {
  try {
    return (await axiosInstance.put<T, AxiosResponse<R>>(url, data)).data;
  } catch (e) {
    console.error(e);
    if (alertError) {
      toastAxiosError(e);
    }
    return null;
  }
}
export async function patchApi<T = never, R = T>(
  url: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/explicit-module-boundary-types
  data: any,
  alertError = true,
): Promise<R | null> {
  try {
    return (await axiosInstance.patch<T, AxiosResponse<R>>(url, data)).data;
  } catch (e) {
    console.error(e);
    if (alertError) {
      toastAxiosError(e);
    }
    return null;
  }
}

export async function deleteApi<T = never, R = T>(
  url: string,
  alertError = true,
): Promise<R | null> {
  try {
    return (await axiosInstance.delete<T, AxiosResponse<R>>(url)).data;
  } catch (e) {
    console.error(e);
    if (alertError) {
      toastAxiosError(e);
    }
    return null;
  }
}

export function getErrorMessage(e) {
  return (
    (e as AxiosError<ApiErrorResult>).response?.data?.detail ??
    (e as AxiosError).message ??
    "Service Unavailable. Please contact administrator or try later."
  );
}

export function toastAxiosError(e): void {
  const { toastError } = useAlertStore();
  toastError(getErrorMessage(e));
}

async function apiRefreshToken(error: AxiosError) {
  try {
    const refreshToken = window.localStorage.getItem("refreshToken");
    if (
      !refreshToken ||
      dayjs((jwt_decode(refreshToken) as { exp: number }).exp * 1000).isBefore(
        dayjs(),
      )
    ) {
      await goSignInPage();
    }
    const { reIssueAccessToken } = useUserStore();
    await reIssueAccessToken();
  } catch (e) {
    console.error(e);
    await goSignInPage();
    return;
  }
  if (!error.config?.headers) {
    console.error("Failed to set 'request headers' : headers is not exist");
    return;
  }
  error.config.headers.AuthorizationR = await getValidatedRefreshToken();
  return error;
}

export async function downloadExcelApi(url: string): Promise<void> {
  dayjs.extend(timezone);
  const response = await axiosInstance.get<Blob>(url, {
    responseType: "blob",
    headers: {
      "Content-Type":
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "X-timezone": dayjs.tz.guess(),
    },
  });
  const newUrl = window.URL.createObjectURL(
    new Blob([response.data], { type: response.headers["content-type"] }),
  );
  const tempLink = document.createElement("a");
  tempLink.style.display = "none";
  tempLink.href = newUrl;
  const contentDisposition = response.headers["content-disposition"];
  tempLink.setAttribute(
    "download",
    (contentDisposition
      ? contentDisposition
          .split("=")
          .pop()
          ?.split(";")
          .join("")
          // eslint-disable-next-line
          .split('"')
          .join("")
      : url.split("/").pop()) || "",
  );
  document.body.appendChild(tempLink);
  tempLink.click();
  document.body.removeChild(tempLink);
  window.URL.revokeObjectURL(newUrl);
}

export function stringifyParams(obj): string {
  return stringify(
    Object.keys(obj)
      .filter((key) => typeof obj[key] !== "string" || obj[key].trim() !== "")
      .reduce((prev, key) => {
        prev[key] = obj[key];
        return prev;
      }, {}),
    { arrayFormat: "repeat", skipNulls: true },
  );
}

export async function getFormDataOfPresignedUploadUrlApi(
  params: PresignedUrlParam,
  file: File,
  uri: string,
  storageUri: string,
): Promise<{ presignedUrl: string | null; formData: FormData }> {
  const response = await getApi<UploadParameters>(
    `${storageUri}v1/${uri}?${stringifyParams(params)}`,
  );
  const formData = new FormData();
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  Object.keys(response.fields).forEach((key) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    formData.append(key, response.fields[key]);
  });

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  formData.append("file", file);
  return { formData, presignedUrl: response?.url ?? null };
}

export async function getFormDataUploadUrlApi(
  params: S3UploadUrlParam,
  file: File,
): Promise<{ formData: FormData }> {
  const token = await getValidatedAccessToken();
  const response = await axios.post<
    {
      bucketName: string;
      dirPath: string;
      fileName: string;
      file: string;
    },
    AxiosResponse<S3Upload>
  >(
    `${API_HOST}api/v1/s3-upload?${stringifyParams(params)}`,
    {
      file: file,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    },
  );
  const formData = new FormData();
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  Object.keys(response.data).forEach((key) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    formData.append(key, response.data[key]);
  });

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  formData.append("file", file);
  return { formData };
}

export async function getCdnUrl(file: File, dirPath: string) {
  const fileName = filterFileName(file.name);
  const fileContentType = file?.type ?? "";

  const { presignedUrl, formData } = await getFormDataOfPresignedUploadUrlApi(
    {
      acl: AclType.PRIVATE,
      projectName: ProjectType.AI_PRIX,
      dirPath: `${ENVIRONMENT}/${dirPath}/${dayjs().format("YYYY-MM-DD")}`,
      fileName,
      contentType: fileContentType,
    },
    file,
    "presigned-upload-parameter",
    FILE_STORAGE_API_URL,
  );
  if (presignedUrl) {
    await axios.postForm(presignedUrl, formData);
    return `${AWS_CDN_DATA_URL}/${formData.get("key")}`;
  }

  return null;
}

export async function getSummaryCdnUrl(file: File, dirPath: string) {
  const fileName = filterFileName(file.name);
  const { formData } = await getFormDataUploadUrlApi(
    {
      bucketName: AWS_S3_DATA_STORAGE,
      dirPath: `${
        ProjectType.AI_PRIX
      }/${ENVIRONMENT}/${dirPath}/${dayjs().format("YYYY-MM-DD")}`,
      fileName,
    },
    file,
  );
  if (formData) {
    return `${AWS_CDN_DATA_URL}/${formData.get("key")}`;
  }
  return null;
}

export async function componentToHTML<T>(
  component: Component,
  props: { [key: string]: T },
): Promise<string> {
  const app = createApp({
    render() {
      return h(component, { props: props });
    },
  });
  const mountedApp = app.mount();
  return mountedApp.$el.outerHTML;
}

export function htmlToPDF(
  html: string,
  fileName: string,
  bizCode?: string | null,
): Promise<File> {
  const fileType = fileName.includes("QUOTATION") ? "quotation" : "booking";
  const orientationType =
    fileType === "quotation" && (bizCode === "TYO" || bizCode === "OSA")
      ? "landscape"
      : "portrait";
  return new Promise<File>((resolve, reject) => {
    html2pdf()
      .set({
        margin: 0.1,
        filename: `${fileName}.pdf`,
        image: { type: "jpeg", quality: 1 },
        jsPDF: {
          unit: "in",
          format: "letter",
          orientation: orientationType,
        },
      })
      .from(html)
      .output("blob")
      .then((pdfData) => {
        const file = new File([pdfData], `${fileName}.pdf`, {
          type: "application/pdf",
        });
        resolve(file);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export async function uploadPDFToCDN(
  file: File,
  category: string,
): Promise<string | null> {
  try {
    const cdnUrl = await getSummaryCdnUrl(file, category);
    return cdnUrl;
  } catch (error) {
    console.error(error);
    return null;
  }
}

export async function submitFile(
  files: File[],
  dirPath: string,
): Promise<Files[]> {
  // AWS 업로드
  if (!files || files.length === 0) return [];

  const cdnUrls = await Promise.all(
    files.map(async (file) => {
      const filteredFileName = filterFileName(file.name);
      const { presignedUrl, formData } =
        await getFormDataOfPresignedUploadUrlApi(
          {
            acl: AclType.PRIVATE,
            projectName: ProjectType.AI_PRIX,
            dirPath: `${ENVIRONMENT}/${dirPath}/${dayjs().format(
              "YYYY-MM-DD",
            )}`,
            fileName: filteredFileName,
            contentType: file.type,
          },
          file,
          "presigned-upload-parameter",
          FILE_STORAGE_API_URL,
        );
      if (presignedUrl) {
        await axios.postForm(presignedUrl, formData);
      }
      const formFilePath = formData.get("key");

      if (!presignedUrl || !formFilePath) {
        return null;
      }

      // NCP 업로드는 AWS의 업로드 이후, 동일한 이름을 사용하여 업로드 진행
      const ncpFileName = encodeURIComponent(
        `${formFilePath}`.split(`${dayjs().format("YYYY-MM-DD")}/`)[1],
      );

      const { presignedUrl: ncpPresignedUrl, formData: ncpFormData } =
        await getFormDataOfPresignedUploadUrlApi(
          {
            acl: AclType.PRIVATE,
            bucketName: ProjectType.AIF,
            dirPath: `${dirPath.toUpperCase()}`,
            fileName: ncpFileName,
            contentType: file.type,
          },
          file,
          "ncp-presigned-upload-parameter",
          `${API_HOST}api/`,
        );

      if (ncpPresignedUrl) {
        await axios.postForm(ncpPresignedUrl, ncpFormData);
        return {
          filePath: `${AWS_CDN_DATA_URL}/${formFilePath}`,
          originalFileName: filteredFileName,
        };
      }
      return { filePath: null, originalFileName: null };
    }),
  );
  return cdnUrls as Files[];
}
