import App from "@/App.vue";
import "@/assets/scss/theme.scss";
import { ENVIRONMENT, SENTRY_DSN } from "@/constants/envs";
import "@/plugins/vee-validate";
import i18n from "@/plugins/vue-i18n"; // NOTE: 반드시 vuetify 전에 import
import vuetify from "@/plugins/vuetify";
import router from "@/router";
import "@/scss/main.scss";
import pinia from "@/stores";
import * as Sentry from "@sentry/vue";
import {
  VueQueryPlugin,
  type VueQueryPluginOptions,
} from "@tanstack/vue-query";
import { PiniaVuePlugin } from "pinia";
import PortalVue from "portal-vue";
import Vue from "vue";
import VueDOMPurifyHTML from "vue-dompurify-html";
import VueGtag from "vue-gtag";
import { PRODUCT_VERSION, API_HOST } from "@/constants/envs";
import axios from "axios";
import { useAlertStore } from "./stores/alert";

const vueQueryPluginOptions: VueQueryPluginOptions = {
  queryClientConfig: {
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
      },
    },
  },
};

Vue.use(PiniaVuePlugin);
Vue.use(PortalVue);
Vue.use(VueQueryPlugin, vueQueryPluginOptions);
Vue.use(VueDOMPurifyHTML);

if (ENVIRONMENT === "prod") {
  Vue.use(
    VueGtag,
    {
      config: { id: "G-PLGCC5PF62" },
    },
    router,
  );
}

if (SENTRY_DSN && ENVIRONMENT !== "local") {
  Sentry.init({
    Vue: Vue,
    dsn: SENTRY_DSN,
    environment: ENVIRONMENT,
    integrations: [new Sentry.Replay()],
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: 1.0,
  });
}

const { toastWarning } = useAlertStore();

function refresh() {
  setTimeout(async function () {
    try {
      const response = await axios.post(`${API_HOST}api/v1/version`, null, {
        headers: {
          "X-Client-Version": PRODUCT_VERSION,
        },
      });
      if (
        response.status === 200 &&
        response.data.error === "DeploymentVersionMismatchException"
      ) {
        toastWarning(i18n.t("msg.versionUpdate").toString());
      }
    } catch (error) {
      // Handle network or other errors here
      console.error("Error:", error);
    }
    // Call the refresh function again to repeat every 15minutes
    refresh();
  }, 900000);
}

refresh();

new Vue({
  pinia,
  router,
  i18n,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");