export default Object.freeze({
  en: {
    pageTitle: "Request",
    quotationNotFoundTitle:
      "Your inquiry need verification with carriers(Air line, Steamship line, Trucker, etc), so it might take some time.",
    quotationNotFoundSubtitle:
      "We will provide you the price as quickly as possible.",
    commentBoxPlaceholder:
      "If there is a comment, it takes more than 3 to 4 hours to provide a quote.",
    confirmPopupTitle: "Quotation Request Confirm",
    confirmPopupContent:
      "Would you like to request a quote with the entered cargo information?",
    confirm: "Confirm",
    cancel: "Cancel",
    sameCntrSize:
      "Same container sizes cannot be selected. Please choose again.",
    polBRU: "We can only provide quotes for departures from {polChange}.",
    polDEBRV: "We can only provide quotes for departures from DEHAM.",
    UnClassPlaceholder: "For plural, type them all separately by ,(comma).",
    gwtWarning: "Please enter TOTAL GWT in the G’WT*PKGS.",
    remarkPlaceholder:
      "In REMARK, you can save JOB NO, SALES PIC., etc. to identify the quotation.",
    copyButton: "If you need a similar quote, please use this.",
    viewQuotation: "View Quotation",
    usUnitChange:
      "If the cargo details are in INCH and LBS, you need to include the units (INCH, LBS) in the AI TEXT.",
    othersUnitChange: "The quote will now be in CM & KG.",
    oneCntrRequired: "One Container is required.",
    changePOLByState: "It will automatically change to the recommended POL. ",
    changePODByState: "It will automatically change to the recommended POD. ",
    selectModeOfTransport: "Please select Mode Of TransPort.",
    hscodePlaceholder:
      "If you need to check the customs duty and tax, please enter the HS CODE.",
    quotationRequestDisable:
      "We apologize, but we cannot provide a quote for this service.",
    quotationFrozenTemperatureHelpText:
      "Please enter the temperature in the Comment box.",
    unavailableNetherlandsQuotation:
      "Due to internal restructuring issues at A.I.F./AMS, some services are currently limited. \nWe apologize for the inconvenience and appreciate your understanding regarding the service you inquired about.",
  },
  ko: {
    quotationNotFoundTitle:
      "접수하신 문의는 협력사와 확인이 필요하여 다소 시간이 소요됩니다.",
    quotationNotFoundSubtitle: "최대한 신속히 가격을 제공 드리겠습니다.",
    commentBoxPlaceholder:
      "Comment가 있을 경우, 견적제공까지 3~4시간이상 걸립니다.",
    confirmPopupTitle: "견적 요청 확인",
    confirmPopupContent: "입력하신 화물 정보로 견적을 요청하시겠습니까?",
    confirm: "확인",
    cancel: "취소",
    sameCntrSize:
      "동일한 컨테이너 크기를 선택할 수 없습니다. 다시 선택해주세요.",
    polBRU: "{polChange}로 견적 안내 드릴 예정입니다.",
    polDEBRV: "DEHAM으로 견적 안내 드릴 예정입니다.",
    UnClassPlaceholder: "2개 이상이면 , 로 이어서 모두 기입해주세요.",
    gwtWarning: "G’WT*PKGS 칸에 TOTAL GWT 를 입력해주세요.",
    remarkPlaceholder:
      "REMARK에는 해당 견적건을 식별할 수 있는 JOB NO, SALES PIC 등을 저장할 수 있습니다.",
    copyButton: "일부 항목만 수정하여 재 문의 시 활용해주세요.",
    viewQuotation: "견적 보기",
    usUnitChange:
      "Cargo detail이 INCH, LBS인 경우, AI TEXT에 단위(INCH, LBS) 표기가 필요합니다.",
    othersUnitChange: "CM & KG 로 견적 단위가 변경됩니다.",
    oneCntrRequired: "Containter 1대는 필수 입력입니다.",
    changePOLByState: "추천 POL로 자동 변경됩니다. ",
    changePODByState: "추천 POD로 자동 변경됩니다. ",
    selectModeOfTransport: "Mode Of TransPort를 선택해주세요.",
    hscodePlaceholder:
      "관부가세 확인이 필요하신 경우, HS CODE 입력부탁드립니다.",
    quotationFrozenTemperatureHelpText:
      "요청 온도를 Comment box에 작성해 주세요.",
    unavailableNetherlandsQuotation:
      "현재 네덜란드 지사 내부 재정비 문제로 일부 서비스를 제한하고 있습니다.\n문의 주신 부분에 대한 서비스 제공이 어려운 현황 양해를 부탁드리겠습니다.",
  },
  "zh-chs": {
    commentBoxPlaceholder:
      "如若Comment Box中有特殊要求的，报价的载入时间可能会延长3~4个小时以上。",
    copyButton: "如果您有相似询价，可以试试Copy键。",
    remarkPlaceholder:
      "如果您想备注订单信息，请填写在“REMARK”里，可以填写贵司业务编号等。",
    usUnitChange:
      "如果货物尺寸单位是INCH和LBS，您需要在AI TEXT中填写尺寸单位或者选择“INCH & LBS”",
    quotationFrozenTemperatureHelpText: "请在评论框中输入请求的温度。",
  },
});
