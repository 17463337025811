import { defineStore } from "pinia";
import jwt_decode from "jwt-decode";
import { getNewToken, signOut } from "@/utils/commands";
import type { FeatureFlag, LoggedInUser } from "@/definitions/types";
import type { AuthorityTypeString } from "@/definitions/authorities";

export const useUserStore = defineStore("user", {
  state: (): LoggedInUser => {
    return {
      id: 0,
      loginId: "",
      name: "",
      truckingUser: "",
      accessToken: "",
      authoritiesJson: [],
      featureFlag: {
        truckingUser: "N",
        trackingUser: "N",
      },
      country: "",
    };
  },
  getters: {
    loggedIn: (state: LoggedInUser): boolean =>
      !!state.id &&
      !!state.accessToken &&
      !!window.localStorage.getItem("accessToken") &&
      !!window.localStorage.getItem("refreshToken"),
    user: (state: LoggedInUser): LoggedInUser => {
      return {
        id: state.id,
        loginId: state.loginId,
        name: state.name,
        truckingUser: state.truckingUser,
        accessToken: state.accessToken,
        authoritiesJson: state.authoritiesJson,
        featureFlag: state.featureFlag,
        country: state.country,
      };
    },
  },
  actions: {
    clearUser(): void {
      window.localStorage.removeItem("accessToken");
      window.localStorage.removeItem("refreshToken");
      this.$reset();
    },
    async reIssueAccessToken(): Promise<void> {
      const newToken = await getNewToken();
      if (newToken) {
        this.saveToken(newToken);
      }
    },
    saveToken(payload: { accessToken: string; refreshToken: string }): void {
      try {
        const jwt = jwt_decode<{
          exp: number;
          id: number;
          loginId: string;
          name: string;
          featureFlag: FeatureFlag;
          truckingUser: string;
          trackingUser: string;
          authoritiesJson: AuthorityTypeString[];
          country: string;
        }>(payload.accessToken);
        window.localStorage.setItem("accessToken", payload.accessToken);
        window.localStorage.setItem("refreshToken", payload.refreshToken);
        this.id = jwt.id;
        this.loginId = jwt.loginId;
        this.name = jwt.name;
        this.truckingUser = jwt.featureFlag.truckingUser;
        this.authoritiesJson = jwt.authoritiesJson;
        this.accessToken = payload.accessToken;
        this.featureFlag = jwt.featureFlag;
        this.country = jwt.country;
      } catch (e: unknown) {
        signOut().then();
      }
    },
    updateUser(payload: Partial<LoggedInUser>): void {
      this.country = payload.country ?? this.country;
    },
  },
  persist: true,
});
