export default Object.freeze({
  en: {
    prixIntroVideo: "PRIX INTRODUCTION",
    login: "LOGIN",
    signup: "REGISTRATION",
    mainTitle: "Begin your new experience with ",
    mainSubTitle1: "PRIX is an online service exclusively for ",
    mainSubTitle1Strong: "‘freight forwarders’",
    openUsImport:
      "We started a new export service for quotation to the U.S.A on April 8th.",
    mainSubTitle1etc: "\nfocusing on import transportation.",
    mainSubTitle2:
      "Unfortunately, shippers or consignee cannot use our service.",
    mainBubbleText1:
      "Currently, our service is available in the Europe, Japan, USA region.",
    mainBubbleText2:
      "AI-integrated Quotations, Bookings, Search History, Newsrooms and Dashboard services are all functions that can be utilized on PRIX.",
    quotationLeftBubble1: "The easiest way to get a quote.",
    quotationLeftBubble2: "Don't type everything out. ",
    quotationLeftBubble2Strong: "Just copy and paste here.",
    quotationLeftBubble3:
      "Depending on the cargo type & case, we will provide immediate service or a response within 24 hours.",
    quotationRightBubble:
      "Even if it’s incomplete, it fills out a majority of the fields.",
    recordsLeftBubble:
      "Looking through your e-mail inbox to find quotation history?",
    recordsRightBubble: "No need to do that anymore.",
    bookingLeftBubble1: "Book your shipment at once.",
    bookingLeftBubble2: "In the Quotation Records!",
    bookingRightBubble: "Enjoy cool events during the beta service period!",
    dashboardLeftBubble1: "Now, would you like to see a quick overview?",
    dashboardLeftBubble2: "Here it is. DASHBOARD.",
    newsroomLeftBubble1: "Are you curious about local news?",
    newsroomLeftBubble2: "Here is the NEWSROOM available to you now.",
    closeTitle: "Why isn’t this service available on PRIX?",
    closeSubTitle1:
      "We will be updating and integrating more new features to PRIX continuously.",
    closeSubTitle2: "",
    footerTitle1: "Now,",
    footerTitle2: "",
  },
  ko: {
    prixIntroVideo: "PRIX 소개 영상",
    login: "로그인",
    signup: "회원가입",
    mainTitle: "와 새로운 경험을 시작하세요.",
    mainSubTitle1: "PRIX는 수입 운송 위주 ",
    mainSubTitle1Strong: "포워더 전용 온라인 서비스 ",
    openUsImport: "4월 8일부터 미주 수출 견적 서비스를 이용할 수 있습니다.",
    mainSubTitle1etc: "입니다.",
    mainSubTitle2: "죄송하지만, 화주님들은 이용이 불가능합니다.",
    mainBubbleText1: "현재, 유럽, 일본,  미국 지역의 서비스가 오픈 되었으며, ",
    mainBubbleText2:
      "AI와 결합된 견적, 부킹, HISTORY 관리, DASHBOARD 서비스를 이용하실 수 있습니다.",
    quotationLeftBubble1: "견적을 받는 가장 쉬운 방법",
    quotationLeftBubble2: "일일이 치지 마세요.",
    quotationLeftBubble2Strong: "여기에 복붙 하세요.",
    quotationLeftBubble3:
      "견적 CASE 에 따라 빠르면 바로, 늦어도 24시간안에 견적을 제공 합니다.",
    quotationRightBubble: "AI 엔진이 꽤 많은 필드를 채워줍니다.",
    recordsLeftBubble: "견적 HISTORY를 찾으려고 메일을 뒤지세요?",
    recordsRightBubble: "더 이상 그러지 마세요.",
    bookingLeftBubble1: "부킹도 그냥 PRIX에서 한번에",
    bookingLeftBubble2: "견적 RECORD에서 바로 부킹!",
    bookingRightBubble: "베타 서비스 기간에 시원한 EVENT도 같이 누리세요~!",
    dashboardLeftBubble1: "이제 모든 정보를 한눈에 보고 싶으시죠?",
    dashboardLeftBubble2: "네. 여기. DASHBOARD.",
    newsroomLeftBubble1: "아, 현지 물류 뉴스도 궁금 하시잖아요?",
    newsroomLeftBubble2: "네. NEWSROOM 준비되어 있습니다.",
    closeTitle: "어, 이건 왜 없지?",
    closeSubTitle1: "네, 아마도 준비 중일 겁니다.",
    closeSubTitle2: "PRIX의 새로운 서비스는 계속 열립니다.",
    footerTitle1: "이제,",
    footerTitle2: "하세요.",
  },
  "zh-chs": {
    prixIntroVideo: "PRIX 介绍视频",
    login: "登录",
    signup: "注册",
    mainTitle: "将给您带来新的体验。",
    mainSubTitle1: "PRIX是为进口",
    mainSubTitle1Strong: "货运代理提供的在线服务。",
    openUsImport: "我们于4月8日开始向美国提供新的出口报价服务。",
    mainSubTitle1etc: "",
    mainSubTitle2: "很抱歉，货主们无法使用。",
    mainBubbleText1: "目前欧洲、日本和美国航线服务已开通，",
    mainBubbleText2:
      "您可以使用结合了AI技术的询价订舱、历史管理、 Newsrooms、Dashboard服务。",
    quotationLeftBubble1: "简便的询价方式",
    quotationLeftBubble2: "无需手动输入字符。",
    quotationLeftBubble2Strong: "只要复制粘贴到这。",
    quotationLeftBubble3: "根据询价内容，及时报价，最晚24小时内提供报价。",
    quotationRightBubble: "AI会帮您自动输入, 但您还是需要检查一下内容哦！",
    recordsLeftBubble: "你还在为寻找历史报价, 翻邮件吗?",
    recordsRightBubble: "No, No, No, 不要再这样做了。",
    bookingLeftBubble1: "在PRIX体验一站式服务",
    bookingLeftBubble2: "直接在报价记录上订舱，So easy！",
    bookingRightBubble: "测试服务期间享受惊喜活动！",
    dashboardLeftBubble1: "是否想要直观的了解所有的信息？",
    dashboardLeftBubble2: "好的。来DASHBOARD（工作台）看看吧！",
    newsroomLeftBubble1: "什么？ 您还想了解A.I.F.分公司的当地物流新闻？",
    newsroomLeftBubble2: "好的。在NEWSROOM中都为您安排上了。",
    closeTitle: "没有其他的服务功能吗？",
    closeSubTitle1: "是的，更多的服务功能正在筹备开发中。",
    closeSubTitle2: "PRIX将持续升级，不断开发新功能为您提供服务。",
    footerTitle1: "现在开始使用",
    footerTitle2: "吧！",
  },
});
