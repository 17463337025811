export default Object.freeze({
  en: {
    banner: {
      title: "PRIX Revamp Announcement",
      description:
        "PRIX will be changed to a new look from August 1. Try it in advance. 🎉",
      link: "Learn More",
    },
    modal: {
      title: "Experience it in advance",
      description: {
        phase1:
          "PRIX service will undergo <strong>a full revamp starting August 1, 2024 (Thursday) at 8:00 AM</strong> \nto provide you with better usability and greater convenience.",
        phase2:
          "We invite you to <strong>preview the BETA version</strong> of the NEW PRIX service, \nallowing you to experience the improvements firsthand. \nWe look forward to your support and engagement during the beta testing period.",
        serviceDate: {
          beta: {
            title: "BETA Period (Simultaneous Operation) :",
            description: " from August 1 to September 22, 2024",
          },
          official: {
            title: "Official Service Launch :",
            description: " September 23, 2024 (Monday) at 8:00 AM",
          },
        },
      },
      button: "Try the NEW PRIX!",
    },
  },
  ko: {
    banner: {
      title: "PRIX 개편 안내",
      description:
        "8월 1일부터 PRIX가 새로운 모습으로 변경될 예정입니다. 미리 사용해보세요. 🎉",
      link: "자세히 알아보기",
    },
    modal: {
      title: "Experience it in advance",
      description: {
        phase1:
          "더 나은 사용성과 편의를 제공하기 위하여 \nPRIX 서비스가 <strong>8월 1일(목) 오전 8시에 전면 개편될 예정입니다.</strong>",
        phase2:
          "<strong>새로워진 PRIX를 가장 먼저 체험</strong>해볼 수 있는\nNEW PRIX의 BETA 버전에 초대드리오니,\nBETA 기간동안 많은 성원과 피드백 부탁드립니다.",
        serviceDate: {
          beta: {
            title: "BETA 기간 (동시 운영) : ",
            description: " 2024년 8월 1일 ~ 2024년 9월 22일",
          },
          official: {
            title: "정식 서비스 시점 : ",
            description: " 2024년 9월 23일(월) 오전 8시",
          },
        },
      },
      button: "새로운 PRIX 이용해보기",
    },
  },
});
