import { defineStore } from "pinia";

export const useMigrationModalStore = defineStore("migration-modal", {
  state: () => ({ isOpened: false }),
  actions: {
    open() {
      this.isOpened = true;
    },
    close() {
      this.isOpened = false;
    },
  },
});

export const useFirstTimeModalStore = defineStore("first-time-modal-cache", {
  state: () => ({ hasBeenViewed: false }),
  actions: {
    markAsViewed() {
      this.hasBeenViewed = true;
    },
  },
  persist: true,
});

export const useMigrationBannerStore = defineStore("migration-banner", {
  state: () => ({ isClosed: false }),
  getters: {
    height: (state) => {
      return state.isClosed ? "0px" : "46px";
    },
  },
  actions: {
    close() {
      this.isClosed = true;
    },
  },
});
