<template>
  <div class="d-flex flex-grow-1" :style="navContainerStyle">
    <!-- Navigation -->
    <v-navigation-drawer
      v-model="drawer"
      :style="drawerStyle"
      dark
      color="primary"
      app
      floating
      class="elevation-1"
      :right="$vuetify.rtl"
      light
    >
      <!-- Navigation menu info -->
      <template #prepend>
        <div class="pa-2">
          <img
            src="@/assets/images/logo/logo_white.png"
            :alt="PRODUCT_TITLE"
            height="32"
            @click="routerPush('/quotation/import')"
          />
        </div>
      </template>
      <!-- Navigation menu -->
      <NavMenu v-if="drawerList.length > 0" :drawers="drawerList" />
      <!-- Navigation menu product-version-->
      <template #append>
        <div class="pa-2 d-flex justify-space-between align-center">
          <div class="d-inline-flex align-center white--text">
            <v-icon color="success" class="mr-1">mdi-check-all</v-icon>
            <span class="text-r-13">Latest release</span>
          </div>
          <div class="text-b-13 white--text">v{{ PRODUCT_VERSION }}</div>
        </div>
      </template>
    </v-navigation-drawer>
    <!-- Toolbar -->
    <v-app-bar app :flat="true" light :style="navStyle">
      <v-card class="flex-grow-1 d-flex pa-0 ma-0" :flat="true">
        <div class="d-flex flex-grow-1 align-center">
          <div class="d-flex flex-grow-1 align-center">
            <v-app-bar-nav-icon @click.stop="drawer = !drawer"
              ><v-icon>{{
                drawer ? "mdi-chevron-triple-left" : "mdi-menu"
              }}</v-icon></v-app-bar-nav-icon
            >
            <v-spacer class="d-none d-lg-block" />
            <v-spacer class="d-block d-sm-none" />
            <ToolbarLanguage />
            <ToolbarAdmin />
            <h4 class="primary--text mt-1" v-text="name" />
          </div>
        </div>
      </v-card>
    </v-app-bar>
    <v-main>
      <v-container class="fill-height pa-0" :fluid="true">
        <v-layout>
          <slot />
          <input type="text" style="width: 0; height: 0" />
        </v-layout>
      </v-container>
    </v-main>
  </div>
</template>
<script setup lang="ts">
import NavMenu from "@/components/navigation/NavMenu.vue";
import { computed, ref } from "vue";
import { storeToRefs } from "pinia";
import { useUserStore } from "@/stores/user";
import type { Drawer } from "@/definitions/types";
import { MenuType } from "@/definitions/selections";
import ToolbarAdmin from "@/components/toolbar/ToolbarAdmin.vue";
import { PRODUCT_TITLE, PRODUCT_VERSION } from "@/constants/envs";
import { useRoute } from "vue-router/composables";
import { routerPush } from "@/utils/commands";
import { useMigrationBannerStore } from "@/stores/migration-modal";
import type { StyleValue } from "vue/types/jsx";

const { name, featureFlag } = storeToRefs(useUserStore());
const route = useRoute();
const drawer = ref(!route.path.includes("/profile"));

const bannerStore = useMigrationBannerStore();
const { height } = storeToRefs(bannerStore);
const navContainerStyle = computed<StyleValue>(() => {
  return {
    "margin-top": height.value,
    position: "relative",
  };
});
const drawerStyle = computed<StyleValue>(() => {
  return {
    height: "100%",
    "padding-top": height.value,
  };
});
const navStyle = computed<StyleValue>(() => {
  return {
    "margin-top": height.value,
  };
});

const truckingEnabled = computed(() => {
  return featureFlag.value.truckingUser === "Y";
});

const drawerList = computed<Drawer[]>(() => {
  const truckingMenu = {
    id: 6,
    name: "Tracking",
    type: MenuType.GROUP,
    icon: "mdi-archive-search-outline",
    url: "null",
    children: [
      {
        id: 61,
        name: "Tracking Inquiry",
        type: MenuType.PAGE,
        icon: null,
        url: "/tracking",
        children: null,
      },
    ],
  };
  const menus: Drawer[] = [
    {
      id: 1,
      name: "Quotation",
      type: MenuType.GROUP,
      icon: "summarize",
      url: null,
      children: [
        {
          id: 11,
          name: "Import Request",
          type: MenuType.PAGE,
          icon: null,
          url: "/quotation/import",
          children: null,
        },
        {
          id: 12,
          name: "Export Request",
          type: MenuType.PAGE,
          icon: null,
          url: "/quotation/export",
          children: null,
        },
        {
          id: 13,
          name: "Quotation Records",
          type: MenuType.PAGE,
          icon: null,
          url: "/quotation/records",
          children: null,
        },
      ],
    },
    {
      id: 2,
      name: "Booking",
      type: MenuType.GROUP,
      icon: "assignment_turned_in",
      url: null,
      children: [
        {
          id: 21,
          name: "Booking Records",
          type: MenuType.PAGE,
          icon: null,
          url: "/booking/records",
          children: null,
        },
      ],
    },
    {
      id: 3,
      name: "Tracking",
      type: MenuType.GROUP,
      icon: "mdi-archive-search-outline",
      url: null,
      children: [
        {
          id: 31,
          name: "Tracking List",
          type: MenuType.PAGE,
          icon: null,
          url: "/tracking/list",
          children: null,
        },
      ],
    },
    {
      id: 4,
      name: "Dashboard",
      type: MenuType.GROUP,
      icon: "mdi-view-dashboard-outline",
      url: "null",
      children: [
        {
          id: 41,
          name: "Dashboard",
          type: MenuType.PAGE,
          icon: null,
          url: "/dashboard",
          children: null,
        },
      ],
    },
    {
      id: 5,
      name: "Board",
      type: MenuType.GROUP,
      icon: "mdi-clipboard-list-outline",
      url: "null",
      children: [
        {
          id: 51,
          name: "News Room",
          type: MenuType.PAGE,
          icon: null,
          url: "/board/news-room",
          children: null,
        },
        {
          id: 52,
          name: "FAQ",
          type: MenuType.PAGE,
          icon: null,
          url: "/board/faq",
          children: null,
        },
      ],
    },
  ];
  return truckingEnabled.value ? [truckingMenu] : [...menus];
});
</script>
