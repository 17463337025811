export default Object.freeze({
  en: {
    qaListTitle: "Q & A LIST",
    qaList1q: "1. Q. I have an FCL inquiry and need CFS STUFFING!",
    qaList1a:
      "A. Enter it as a basic FCL rate inquiry and detail your requirements <br/> &nbsp; &nbsp; &nbsp;in the COMMENT BOX.",
    qaList2q:
      "2. Q. I need freight rates for the same cargo but to multiple POD.",
    qaList2a:
      "A. Please enter only one POD in the field and first submit a quote request. <br/> &nbsp; &nbsp; Then, retrieve the quote request from 'QUOTATION RECORDS' and click <br/> &nbsp; &nbsp; 'COPY' at the top right corner. You can conveniently use this by <br/> &nbsp; &nbsp; only modifying the POD when submitting the quote.",
    qaList3q: "3. Q. I'm not sure which POL to specify.",
    qaList3a:
      "A. If you're unsure about the POL, please select \"Port recommend\".",
    qaList4q: "4. Q. The POD I need isn't listed.",
    qaList4a:
      "A. If your required POD isn't available, select \"ETC\" and specify the POD <br/> &nbsp; &nbsp; &nbsp; manually.",
    qaList5q: "5. Q. It's DG and also Perishable.",
    qaList5a:
      "A. Enter it as DG and provide the requested temperature and ITEM<br/> &nbsp; &nbsp; &nbsp; details in the COMMENT BOX.",
    qaList6q: "6. Q. I need both air and sea freight rates.",
    qaList6a:
      "A.  Please submit a quote request for one SHIPPING MODE first, <br/> &nbsp; &nbsp; &nbsp;then retrieve  the quote request from 'QUOTATION RECORDS' and click <br/> &nbsp; &nbsp; &nbsp;'COPY' at the top right corner. You can conveniently use this by only <br/> &nbsp; &nbsp; &nbsp;modifying the SHIPPING MODE when submitting the quote.",
    qaList7q:
      "7. Q. I want to update a quote similar to one I've received before!",
    qaList7a:
      "A. Go to QUOTATION RECORDS -> click on the relevant quotation -> click <br/> &nbsp; &nbsp; &nbsp; COPY in the top right corner.",
    qaList8q:
      "8. Q. It's STACKABLE, but only the TRUCKING is UNSTACKABLE <br/> &nbsp; &nbsp; &nbsp;(or vice versa).",
    qaList8a:
      "A. Specify based on the SHIPPING MODE and explain further in the <br/> &nbsp; &nbsp; &nbsp; COMMENT BOX.",
    aiTitle: "How to use AI QUOTATION",
    aiList1:
      "1. Currently, only quotations for imported shipment such as FOB, FCA, EXW <br/> &nbsp; &nbsp; are available.",
    aiList2:
      "2. Copy the quotation request text you've received from the CONSIGNEE <br/> &nbsp; &nbsp;  and paste it into the box under CARGO DETAIL.",
    aiList3:
      "3. Click the AI ANALYSIS button below the box. (The time it takes to extract <br/> &nbsp; &nbsp;  will be shown, depending on the amount of text.)",
    aiList4:
      "4. Once extraction is complete, the fields on the right and below will be <br/> &nbsp; &nbsp; automatically filled with the quotation information.",
    aiList5:
      "5. Check if all fields marked with an * in front are filled, then click the<br/> &nbsp; &nbsp;  REQUEST button on the bottom right.",
    aiList6: "6. Quotations are provided immediately for eligible cases.",
    aiList7:
      "7. For cases that require verification with airlines, shipping companies, <br/> &nbsp; &nbsp;  or carriers, we will provide a quotation within 24 hours at the latest.",
    aiList8:
      "8. While the AI extracts quite accurately, there might be errors, <br/> &nbsp; &nbsp; <span style=\"color:red; font-weight:bold\">so please check result thoroughly</span> and then click the REQUEST button.",
    gptTitle: "Tips for AI using",
    gptList1:
      "1. When pasting text, specifying the SHIP MODE (AIR, LCL, FCL) increases <br/> &nbsp; &nbsp; extraction accuracy.",
    gptList2:
      "2. Remove general content like greetings and paste only the pure inquiry to <br/> &nbsp; &nbsp; speed up the process.",
    gptList3:
      "3. CARGO DETAIL has a maximum of 30 lines. For cases exceeding this, <br/> &nbsp; &nbsp; please paste into the COMMENT BOX on the bottom right.",
    dashBoardTitle: "What is a Dashboard?",
    dashBoardContent:
      "In the 'Dashboard', you can see the number of quotations and bookings by <br/> country inquired through PRIX 'Shipment History' is information on the <br/>number of imports by ship mode as of this year.",
    bookingRequestTitle: "What is a Booking Request?",
    bookingRequestContent:
      "In 'Booking Request', you can immediately book the quotation you  <br/>inquired through PRIX, and it will be arranged as a priority. <br/> <br/>" +
      "Please enter the requested information in 'Booking Details' on the bottom left, <br/> and once you click \"Save\" -> \"Booking\", it will be processed immediately.<br/>",
    bookingRecordsTitle: "What is a Booking Records?",
    bookingRecordsContent:
      "In 'Booking Records', you can check booking history through PRIX.",
  },
  ko: {
    qaListTitle: "Q & A LIST",
    qaList1q: "1. Q. FCL 문의인데, CFS SUFFING 이 필요해요!",
    qaList1a:
      "A. FCL 기본 운임 문의로 넣고, COMMENT BOX에 상세 내용 기입해주세요.",
    qaList2q: "2. Q. 같은 화물이지만 다수 POD 운임이 필요해요!",
    qaList2a:
      "A. POD 하나만 필드에 입력하고 먼저 견적을 접수하시고, 'Quoatation Records' <br/> &nbsp; &nbsp; &nbsp;에서 해당 견적 의뢰를 불러오신 후, 우측 상단의 'Copy'를 클릭해주세요. <br/> &nbsp; &nbsp; &nbsp;POD만 수정하여 견적 접수 시 편리하게 이용하실 수 있습니다.",
    qaList3q: "3. Q. POL을 어디로 지정해야 하는지 모르겠어요...",
    qaList3a: "A. POL이 명확하지 않다면 'Port recommend'를 선택해주세요.",
    qaList4q: "4. Q. 제가 필요한 POD가 없어요...",
    qaList4a:
      "A. 요청 POD가 없다면, 'ETC' 선택하시고, POD를 직접 기입해주세요.",
    qaList5q: "5. Q. DG 이면서 Perishable 이에요...",
    qaList5a:
      "A. DG 로 기입해주시고, 요청온도와 ITEM은 COMMENT BOX에 기입해주세요~",
    qaList6q: "6. Q. 항공, 해상 운임 모두 필요해요!",
    qaList6a:
      "A. 둘 중 하나의 Shipping Mode로 먼저 견적을 접수하시고, <br/> &nbsp; &nbsp; 'Quotation Records'에서 해당 견적 의뢰를 불러오신 후, 우측 상단의 'Copy' <br/> &nbsp; &nbsp; 를 클릭해주세요. Shipping Mode만 수정하여 견적 접수 시 편리하게 이용하 <br/> &nbsp; &nbsp; 실 수 있습니다.",
    qaList7q: "7. Q. 기존에 견적 한 내역과 비슷한 견적을 업데이트 받고 싶어요!",
    qaList7a: "A. QUOTATION RECORDS -> 해당 견적건 클릭 -> 우상단에 COPY 클릭!",
    qaList8q:
      "8. Q. STACKABLE 인데, TRUCKING만 UNSTACKABLE 이에요... (혹은 반대)",
    qaList8a:
      "A. SHIPPING MODE 기준으로 지정하시고, COMMENT BOX에 설명해주세요.",

    aiTitle: "AI QUOTATION 사용 방법",
    aiList1: "1. 현재는 FOB, FCA, EXW 등 수입 화물 견적만 가능합니다.",
    aiList2:
      "2. 화주분으로부터 받은 견적 요청 TEXT를 COPY하셔서, <br/> &nbsp; &nbsp; CARGO DETAIL 하단의 네모박스에 붙여넣기 하세요.",
    aiList3:
      "3. 네모박스 하단에 AI ANALYSIS 버튼을 눌러 주세요. <br/> &nbsp; &nbsp; (TEXT 양에 따라 추출되는 데까지 걸리는 시간이 표기 됩니다.)",
    aiList4:
      "4. 추출이 완료되면, 우측과 하단의 견적 정보 필드에 내용이 자동으로 채워 집니다.",
    aiList5:
      "5. 각 입력 필드 앞에 *표시가 있는 필드가 모두 채워졌는지 확인하시고, <br/> &nbsp; &nbsp; 우측 하단의 REQUEST 버튼을 눌러주세요.",
    aiList6: "6. 즉시 견적이 가능한 CASE는 바로 견적이 제공됩니다.",
    aiList7:
      "7. 항공사, 선사, 운송사 등과 확인이 필요한 CASE의 경우 늦어도 <br/> &nbsp; &nbsp; 24시간 안으로 견적을 제공 드립니다.",
    aiList8:
      "8. AI가 꽤 정확하게 추출을 하지만, 오류가 있을 수도 있으니,<br/> &nbsp; &nbsp; 고객님께서 <span style='color:red; font-weight:bold'>최종 확인 해주시고</span> REQUEST 버튼을 눌러주세요.",
    gptTitle: "AI 100% 활용 팁!",
    gptList1:
      "1. TEXT 붙여넣기 시에, SHIP MODE(AIR, LCL, FCL)를<br/> &nbsp; &nbsp; 명기하시면 추출 정확도가 올라갑니다.",
    gptList2:
      "2. 인사말 등 일반적인 내용은 삭제하시고 순수 문의만 <br/> &nbsp; &nbsp;&nbsp;붙여 넣어 주시면 속도가 더 빨라집니다.",
    gptList3:
      "3. CARGO DETAIL은 30줄이 MAX 이며, 넘어가는 CASE는 <br/> &nbsp; &nbsp;&nbsp;우측 하단 COMMENT BOX에 붙여넣기 해주세요.",
    dashBoardTitle: "What is a Dashboard?",
    dashBoardContent:
      "'Dashboard'에서는 PRIX를 통해 문의하신 국가별 견적수와 부킹 건수를 보실 수<br/> 있습니다. 'Shipment History'는 금년  기준 진행한 수입 물량 건수 정보이며, <br/>ship mode 별로 나누어 보실 수 있습니다.",
    bookingRequestTitle: "What is a Booking Request?",
    bookingRequestContent:
      "'Booking Request'는 PRIX를 통해 문의하신 견적건을 <br/> 바로 Booking 하실 수 있으며, 우선 순위로 arrange 됩니다. <br/><br/>" +
      "왼쪽 하단 'Booking Details'에 요청 드리는 정보를 입력하시고, <br/> Save -> Booking을 클릭하시면 즉시 진행됩니다.<br/>",
    bookingRecordsTitle: "What is a Booking Records?",
    bookingRecordsContent:
      "'Booking Records' 에서는 PRIX를 통해 booking 하신 기록을 보실 수 있습니다.<br/>",
  },
  "zh-chs": {
    qaListTitle: "我有个问题！",
    qaList1q: "1. Q> 整箱询价，需要CFS SUFFING！",
    qaList1a: "A> 请选FCL询价，并在COMMENT BOX上输入详细信息。",
    qaList2q: "2. Q> 同样的货物信息，需要多个POD的价格",
    qaList2a:
      "A> 首先选择一个POD，提交询价。进去'Quotation Records'，<br/> &nbsp; &nbsp; &nbsp; 点击刚才的询价，点击右上角的'Copy'只修改POD，就可以完成提交询价",
    qaList3q: "3. Q> 没有我需要的POD。",
    qaList3a: "A> 如果没有您需要的POD，请选择“ETC”，并直接输入POD。",
    qaList4q: "4. Q> 危险品询价，我有2个以上UN#",
    qaList4a: "A> 请输入一个有代表性的UN#，其余的请填写在COMMENT BOX内。",
    qaList5q: "5. Q> 我不知道该选哪个POL。",
    qaList5a: "A> 如果不清楚，请选择“Port recommend”。",
    qaList6q: "6. Q> 空运、海运费都需要",
    qaList6a:
      "A> 首先选择一个Shipping mode，提交询价。进去'Quotation Records'，<br/>&nbsp; &nbsp;&nbsp; 点击刚才的询价，点击右上角的'Copy'只修改Shipping mode，<br/>&nbsp; &nbsp;&nbsp; 就可以完成提交询价",
    qaList7q: "7. Q> 我想收到与之前询价明细相似的报价！",
    qaList7a: "A> QUOTATION RECORDS-> 点击该询价单-> 点击右上角的COPY！",
    aiTitle: "AI QUOTATION 使用方法",
    aiList1: "1. 目前仅提供FOB、FCA、EXW等进口价格。",
    aiList2:
      "2. 复制从客户（货主）收到的询价内容并将其粘贴到“CARGO DETAIL” <br/>&nbsp; &nbsp;&nbsp;下面的 方框。",
    aiList3: "3. 按方框底部的AI ANALYSIS按钮。（根据文字量显示提取所需的时间）",
    aiList4: "4. 提取完成后，AI会帮您自动输入到右侧和底部的询价信息字段中。",
    aiList5:
      "5. *标记的字段为必要内容，请确认内容已经输入, 然后按下右下角的 <br/>&nbsp; &nbsp;&nbsp;REQUEST按钮。",
    aiList6: "6. 根据询价内容，及时报价。",
    aiList7:
      "7. 如需与航空公司、船公司、运输公司等确认的话，最晚24小时内提供报价。",
    aiList8:
      "8. AI会帮您自动输入，但可能会有错误。请检查一下内容，然后按下<br/>&nbsp; &nbsp;&nbsp;“REQUEST”按钮。",
    gptTitle: "AI 100% 使用提示!",
    gptList1: "1. 粘贴内容时，指定运输模式（AIR、LCL、FCL）可提高提取准确度。",
    gptList2: "2. 您可以删掉不需要的内容，只粘贴相关询价的内容，速度会更快。",
    gptList3:
      "3. CARGO DETAIL最多可输入三十行内容，超过三十行的内容请粘贴到右下角 <br/>&nbsp; &nbsp;&nbsp;的COMMENT BOX。",
    dashBoardTitle: "What is a Dashboard?",
    dashBoardContent:
      "在'Dashboard'中，您可以查看通过PRIX询价、订舱的历史订单。<br/>'Shipment History'是本年度进口货物票数信息，可以按运输方式查看。",
    bookingRequestTitle: "What is a Booking Request?",
    bookingRequestContent:
      "在'Booking Request'中，您可以直接预订通过PRIX询价的单子， <br/>我们将优先安排订舱。<br/><br/> 请在左下角的'Booking Details'中输入订舱信息，单击 'Save'-> 'Booking'， <br/>即下单成功。",
    bookingRecordsTitle: "What is a Booking Records?",
    bookingRecordsContent:
      "在'Booking Records'中，您可以查看通过PRIX订舱的记录。",
  },
});
