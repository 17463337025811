<template>
  <v-app>
    <input type="text" style="width: 0; height: 0" />
    <migration-banner v-if="!isClosed" />
    <component :is="currentLayout" v-if="isRouterLoaded">
      <transition name="fade" mode="out-in">
        <router-view />
      </transition>
    </component>
    <MigrationModal />
    <GlobalSnackbarAlert />
    <GlobalDialogConfirm /> <input type="text" style="width: 0; height: 0" />
  </v-app>
</template>

<script setup lang="ts">
import { PRODUCT_TITLE } from "@/constants/envs";
import AuthLayout from "@/layouts/AuthLayout.vue";
import DefaultLayout from "@/layouts/DefaultLayout.vue";
import ErrorLayout from "@/layouts/ErrorLayout.vue";
import ProfilesLayout from "@/layouts/ProfilesLayout.vue";
import SimpleLayout from "@/layouts/SimpleLayout.vue";
import { computed, onMounted } from "vue";
import { useRoute } from "vue-router/composables";

import GlobalDialogConfirm from "@/views/components/dialog/GlobalDialogConfirm.vue";
import GlobalSnackbarAlert from "@/views/components/dialog/GlobalSnackbarAlert.vue";
import MigrationBanner from "@/components/migration/migration-banner.vue";
import MigrationModal from "@/components/migration/migration-modal.vue";
import { useMigrationBannerStore } from "@/stores/migration-modal";
import { storeToRefs } from "pinia";

const layouts = {
  default: DefaultLayout,
  auth: AuthLayout,
  error: ErrorLayout,
  simple: SimpleLayout,
  profiles: ProfilesLayout,
};

const bannerStore = useMigrationBannerStore();
const { isClosed } = storeToRefs(bannerStore);

const route = useRoute();
const isRouterLoaded = computed((): boolean => route.name !== null);
const currentLayout = computed(() => layouts[route.meta?.layout || "default"]);

onMounted(() => {
  document.title = PRODUCT_TITLE;
});
</script>
