export const PRODUCT_TITLE: string = import.meta.env.VITE_PRODUCT_TITLE;
export const PRODUCT_VERSION: string = import.meta.env.VITE_PRODUCT_VERSION;
export const API_HOST: string = import.meta.env.VITE_BASE_API_URL;
export const LOCALE: string = import.meta.env.VITE_LOCALE;
export const DATE_FORMAT_STRING: string = import.meta.env
  .VITE_DATE_FORMAT_STRING;
export const DATE_MONTH_FORMAT_STRING: string = import.meta.env
  .VITE_DATE_MONTH_FORMAT_STRING;
export const DATETIME_FORMAT_STRING: string = import.meta.env
  .VITE_DATETIME_FORMAT_STRING;
export const TIME_FORMAT_STRING: string = import.meta.env
  .VITE_TIME_FORMAT_STRING;
export const DATETIME_MINUTE_FORMAT_STRING: string = import.meta.env
  .VITE_DATETIME_MINUTE_FORMAT_STRING;
export const TIME_MINUTE_FORMAT_STRING: string = import.meta.env
  .VITE_TIME_MINUTE_FORMAT_STRING;
export const SENTRY_DSN: string = import.meta.env.VITE_SENTRY_DSN;
export const ENVIRONMENT: string = import.meta.env.VITE_ENVIRONMENT;
export const AWS_S3_DATA_STORAGE: string = import.meta.env
  .VITE_AWS_S3_DATA_STORAGE;
export const AWS_CDN_DATA_URL: string = import.meta.env.VITE_AWS_CDN_DATA_URL;
export const NCP_CDN_DATA_URL: string = import.meta.env.VITE_NCP_CDN_DATA_URL;
export const FILE_STORAGE_API_URL: string = import.meta.env
  .VITE_FILE_STORAGE_API_URL;
export const FOOTER_PROPS_MAX_100: {
  showFirstLastPage: boolean;
  itemsPerPageOptions: number[];
} = {
  showFirstLastPage: true,
  itemsPerPageOptions: [10, 20, 50, 100],
};
export const PRODUCT_NAME: string = import.meta.env.VITE_PRODUCT_NAME;
export const GA_TRACKING_ID: string = import.meta.env.VITE_GA_TRACKING_ID;
