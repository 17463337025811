<template>
  <div>
    <v-menu offset-y left transition="slide-y-transition">
      <template #activator="{ on }">
        <v-btn icon class="elevation-2" v-on="on">
          <v-badge color="success" dot bordered offset-x="10" offset-y="10">
            <v-avatar color="grey lighten-2">
              <v-icon color="white"> mdi-account-circle </v-icon>
            </v-avatar>
          </v-badge>
        </v-btn>
      </template>

      <v-list dense nav class="toolbar-menu">
        <v-list-item
          v-for="drawer in drawerList"
          :key="drawer.id"
          link
          :href="drawer.url"
        >
          <v-list-item-icon v-if="drawer.icon">
            <v-icon> {{ drawer.icon }} </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title> {{ drawer.name }} </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item @click="signOut">
          <v-list-item-icon>
            <v-icon>mdi-logout-variant</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title> {{ $t("msg.logout") }} </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script setup lang="ts">
import { signOut } from "@/utils/commands";
import type { Drawer } from "@/definitions/types";
import { MenuType } from "@/definitions/selections";

const drawerList: Drawer[] = [
  {
    id: 1,
    name: "Company Account",
    type: MenuType.PAGE,
    icon: null,
    url: "/profile/company-account/",
    children: null,
  },
  {
    id: 2,
    name: "Change Profile",
    type: MenuType.PAGE,
    icon: null,
    url: "/profiles",
    children: null,
  },
  {
    id: 3,
    name: "Change Password",
    type: MenuType.PAGE,
    icon: null,
    url: "/profile/change-password/",
    children: null,
  },
];
</script>
