import Vue from "vue";
import VueI18n from "vue-i18n";
import { useLanguageStore } from "@/stores/language";
import msg from "@/translations/msg";
import stores from "@/stores";
import quotation from "@/translations/quotation";
import booking from "@/translations/booking";
import tooltip from "@/translations/tooltip";
import { LanguageType } from "@/definitions/selections";
import landing from "@/translations/landing";
import tracking from "@/translations/tracking";
import newLanding from "@/translations/new-landing";

Vue.use(VueI18n);
const { language } = useLanguageStore(stores);
const i18n = new VueI18n({
  locale: language,
  fallbackLocale: LanguageType.ENGLISH,
  messages: {
    en: {
      msg: msg.en,
      quotation: quotation.en,
      booking: booking.en,
      landing: landing.en,
      tooltip: tooltip.en,
      tracking: tracking.en,
      newLanding: newLanding.en,
    },
    ko: {
      msg: msg.ko,
      quotation: quotation.ko,
      booking: booking.ko,
      landing: landing.ko,
      tooltip: tooltip.ko,
      tracking: tracking.ko,
      newLanding: newLanding.ko,
    },
    "zh-chs": {
      msg: msg["zh-chs"],
      quotation: quotation["zh-chs"],
      booking: booking["zh-chs"],
      landing: landing["zh-chs"],
      tooltip: tooltip["zh-chs"],
      tracking: tracking["zh-chs"],
    },
  },
});

export default i18n;
