export default Object.freeze({
  en: {
    etaEtdMessage:
      "** The ETA notification service for ocean shipments is under preparation.",
    arrivalMessage:
      "** The notification service for 'Arrival Completed' status for ocean shipments is under preparation.",
  },
  ko: {
    etaEtdMessage: "** 해상의 ETA 알림 서비스는 준비 중입니다.",
    arrivalMessage:
      "** 해상 운송 건의 '도착 완료' 상태에 대한 알림 서비스는 준비 중입니다.",
  },
  "zh-chs": {},
});
