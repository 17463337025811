import { isValidTokens } from "@/utils/commands";
import PassThrough from "@/views/error/PassThrough.vue";
import Vue, { computed } from "vue";
import type { Route } from "vue-router";
import Router from "vue-router";
import type { RouteConfig } from "vue-router/types/router";
import { storeToRefs } from "pinia";
import { useUserStore } from "@/stores/user";
Vue.use(Router);
const PUBLIC_PAGES: RouteConfig[] = [
  {
    path: "/public/email-verification",
    meta: {
      layout: "auth",
      requiresAuth: false,
    },
    component: () => import("@/views/verification/EmailVerification.vue"),
  },
  {
    path: "/public/forgot-password",
    meta: {
      layout: "auth",
      requiresAuth: false,
    },
    component: () => import("@/views/verification/ForgotPassword.vue"),
  },
];
const routes = (): RouteConfig[] => {
  const quotation: RouteConfig[] = [
    {
      path: "import",
      name: "import",
      component: () => import("@/views/quotation/QuotationRequestPage.vue"),
    },
    {
      path: "export",
      name: "export",
      component: () =>
        import("@/views/quotation-export/QuotationExportPage.vue"),
    },
    {
      path: "records",
      component: () =>
        import("@/views/quotation/records/QuotationRecordsPage.vue"),
    },
    {
      path: "records/details/:id",
      component: () =>
        import("@/views/quotation/records/QuotationRecordsDetails.vue"),
    },
  ];
  const booking: RouteConfig[] = [
    {
      path: "request/:id",
      component: () => import("@/views/booking/BookingRequestPage.vue"),
    },
    {
      path: "records",
      component: () => import("@/views/booking/BookingRecordsPage.vue"),
    },
  ];
  const board: RouteConfig[] = [
    // TODO: 추후 주석 제거하여 다시 사용 예정
    // {
    //   path: "knowledge-sharing",
    //   component: () => import("@/views/board/list/BoardPage.vue"),
    // },
    // {
    //   path: "knowledge-sharing/:id",
    //   component: () => import("@/views/board/details/BoardDetailsPage.vue"),
    // },
    {
      path: "news-room",
      component: () => import("@/views/board/news-room/list/BoardPage.vue"),
    },
    {
      path: "faq",
      component: () => import("@/views/board/faq/FAQPage.vue"),
    },
    {
      path: "news-room/:id",
      component: () =>
        import("@/views/board/news-room/details/BoardDetailsPage.vue"),
    },
    {
      path: "faq/:id",
      component: () =>
        import("@/views/board/news-room/details/BoardDetailsPage.vue"),
    },
    // {
    //   path: "notice",
    //   component: () => import("@/views/board/list/BoardPage.vue"),
    // },
    // {
    //   path: "notice/:id",
    //   component: () => import("@/views/board/details/BoardDetailsPage.vue"),
    // },
  ];
  const profile: RouteConfig[] = [
    {
      path: "company-account",
      component: () =>
        import("@/views/profile/company-account/ProfileCompanyAccountPage.vue"),
      meta: {
        layout: "default",
      },
    },
    {
      path: "change-password",
      component: () =>
        import("@/views/profile/change-password/ProfileChangePasswordPage.vue"),
      meta: {
        layout: "default",
      },
    },
  ];
  const error: RouteConfig[] = [
    {
      path: "/error",
      component: () => import("@/views/error/UnexpectedPage.vue"),
      meta: {
        layout: "error",
      },
    },
    {
      path: "/error/404",
      component: () => import("@/views/error/NotFoundPage.vue"),
      meta: {
        layout: "error",
      },
    },
  ];
  return [
    {
      path: "/",
      component: () => import("@/views/landing/LandingPage.vue"),
      meta: {
        requiresAuth: false,
        layout: "simple",
      },
    },
    {
      path: "/sign-in",
      component: () => import("@/views/sign/in/SignIn.vue"),
      meta: {
        layout: "auth",
      },
    },
    {
      path: "/sign-up",
      component: () => import("@/views/sign/up/SignUp.vue"),
      meta: {
        layout: "auth",
      },
    },
    {
      path: "/profiles",
      component: () => import("@/views/profiles/ProfileSelectPage.vue"),
      meta: { requiresAuth: true, layout: "profiles" },
    },
    {
      path: "/manage-profiles",
      component: () => import("@/views/profiles/ManageProfilesPage.vue"),
      meta: { requiresAuth: true, layout: "profiles" },
    },
    {
      path: "/quotation",
      redirect: "/quotation/import",
      component: PassThrough,
      meta: {
        requiresAuth: true,
      },
      children: quotation,
    },
    {
      path: "/booking",
      redirect: "/booking/records",
      component: PassThrough,
      meta: {
        requiresAuth: true,
      },
      children: booking,
    },
    {
      path: "/tracking/list",
      component: () => import("@/views/tracking/TrackingPage.vue"),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/board",
      redirect: "/board/news-room",
      component: PassThrough,
      meta: {
        requiresAuth: true,
      },
      children: board,
    },
    {
      path: "/tracking",
      component: () => import("@/views/tracking/us/UsTrackingPage.vue"),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/dashboard",
      component: () => import("@/views/dashboard/DashboardPage.vue"),
      meta: {
        requiresAuth: true,
        layout: "default",
      },
    },
    {
      path: "/ai-test",
      component: () => import("@/views/test-ai/AiTest.vue"),
      meta: {
        layout: "default",
      },
    },
    {
      path: "/test",
      component: () => import("@/views/test-ai/TestPage.vue"),
      meta: {
        layout: "default",
      },
    },
    {
      path: "/profile",
      redirect: "/profile/company-account",
      component: PassThrough,
      meta: {
        requiresAuth: true,
      },
      children: profile,
    },
    ...PUBLIC_PAGES,
    ...error,
    {
      path: "*",
      redirect: "/error/404",
    },
  ];
};
const router = new Router({
  mode: "history",
  base: import.meta.env.BASE_URL,
  scrollBehavior(_to: Route, _from: Route, savedPosition) {
    return savedPosition || { x: 0, y: 0 };
  },
  routes: routes(),
});
const ZOOM_EXCLUDES_PATHS = ["/"];
const setZoom = (currentPath: string) => {
  const app = document.querySelector("body") as HTMLElement;
  if (ZOOM_EXCLUDES_PATHS.includes(currentPath)) {
    app.classList.remove("is-active-zoom");
    return;
  }
  if (app.classList.contains("is-active-zoom")) return;
  app.classList.add("is-active-zoom");
};

const isAccessableTrucking = (path: string) => {
  const restrictedPaths = ["quotation", "booking", "dashboard", "board"];
  return restrictedPaths.filter((word) => path.includes(word)).length === 0;
};

const truckingController = (to, next, truckingEnabled) => {
  if (truckingEnabled) {
    const isAccessable = isAccessableTrucking(to.path);
    if (!isAccessable) {
      return next({
        path: "/error",
      });
    }
  }
  if (!truckingEnabled && to.path === "/tracking") {
    window.localStorage.clear();
    return next({
      path: "/",
    });
  }
};

const authController = (to, next, isValid, selectedProfile) => {
  if (!isValid) {
    window.localStorage.clear();
    return next({
      path: "/sign-in",
    });
  }
  if (!selectedProfile && to.path !== "/profiles") {
    if (to.path === "/manage-profiles") return next();
    return next({
      path: "/profiles",
    });
  }
};

router.beforeEach(async (to, _, next) => {
  const isRequiredAuth = to.matched.some((record) => record.meta.requiresAuth);
  const isValid = await isValidTokens();
  const selectedProfile = window.localStorage.getItem("X-Profile-Id");
  const { featureFlag } = storeToRefs(useUserStore());
  const truckingEnabled = computed(() => {
    return featureFlag.value.truckingUser === "Y";
  });

  truckingController(to, next, truckingEnabled.value);

  if (isRequiredAuth) {
    authController(to, next, isValid, selectedProfile);
  }
  return next();
});
router.afterEach((to) => {
  setZoom(to.path);
});
export default router;
