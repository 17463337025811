import { defineStore } from "pinia";
import type { Alert } from "@/definitions/types";
import Vuetify from "@/plugins/vuetify";
import { v4 as uuidV4 } from "uuid";

export const useAlertStore = defineStore("alert", {
  state: () => {
    return {
      items: [] as Alert[],
    };
  },
  actions: {
    toastSuccess(message: string, timer = 2_000) {
      this.alert(
        message,
        timer,
        Vuetify.framework.theme.themes.light.success?.toString() ?? "",
        "mdi-check-circle",
      );
    },
    toastError(message: string, timer = 10_000) {
      this.alert(
        message,
        timer,
        Vuetify.framework.theme.themes.light.error?.toString() ?? "",
        "mdi-alert-circle",
      );
    },
    toastInfo(message: string, timer = 5_000) {
      this.alert(
        message,
        timer,
        Vuetify.framework.theme.themes.light.info?.toString() ?? "",
        "mdi-information",
      );
    },
    toastWarning(message: string, timer = 5_000) {
      this.alert(
        message,
        timer,
        Vuetify.framework.theme.themes.light.warning?.toString() ?? "",
        "mdi-alert",
      );
    },
    alert(message: string, timer: number, color: string, icon: string) {
      this.$state.items = [
        ...this.$state.items,
        {
          uuid: uuidV4(),
          message: message,
          timer: timer,
          color: color,
          icon: icon,
        },
      ];
    },
    toastClose(item: Alert) {
      this.$state.items = this.$state.items.filter((v) => v !== item);
    },
    toastClear() {
      this.$state.items = [];
    },
  },
});
