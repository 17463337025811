<template>
  <div
    style="
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: #4d6deb;
      height: 46px;
      position: fixed;
      top: 0;
      z-index: 10;
      padding: 12px 24px;
      color: #fff;
    "
  >
    <div style="display: flex; align-items: center">
      <v-icon size="medium" style="color: #fff; margin-right: 8px"
        >mdi-information</v-icon
      >
      <p
        style="
          margin-bottom: 0;
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: 20px;
          margin-right: 16px;
        "
      >
        {{ $t("newLanding.banner.title") }}
      </p>
      <p
        style="
          margin-bottom: 0;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 22px;
        "
      >
        {{ $t("newLanding.banner.description") }}
      </p>
    </div>
    <div style="display: flex">
      <v-btn
        variant="plain"
        style="
          background-color: transparent;
          color: #fff;
          margin-bottom: 0;
          font-size: 12px;
          font-style: normal;
          font-weight: 600;
          line-height: 16px;
          letter-spacing: -0.2px;
          text-decoration-line: underline;
        "
        @click="migrationModal.open()"
      >
        {{ $t("newLanding.banner.link") }}
      </v-btn>
      <v-btn icon @click="bannerStore.close()">
        <v-icon size="medium" style="color: #fff">mdi-close</v-icon>
      </v-btn>
    </div>
  </div>
</template>
<script setup lang="ts">
import {
  useMigrationBannerStore,
  useMigrationModalStore,
} from "@/stores/migration-modal";

const migrationModal = useMigrationModalStore();
const bannerStore = useMigrationBannerStore();
</script>
