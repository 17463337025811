<template>
  <div>
    <v-list-item
      v-if="drawer.type !== MenuType.GROUP"
      :to="drawer.url"
      :link="drawer.type === MenuType.PAGE"
      :target="drawer.type === MenuType.NEW_TAB ? '_blank' : undefined"
      class="hover:white--text"
      active-class="white--text"
      :style="{
        'padding-left': `32px`,
      }"
      @click="resetComponent"
    >
      <v-list-item-icon v-if="drawer.icon">
        <v-icon v-text="drawer.icon" />
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title class="text-r-15" v-text="drawer.name" />
      </v-list-item-content>
    </v-list-item>

    <v-list-group
      v-else
      :value="true"
      active-class="secondary--text opacity-100"
      :style="{
        'margin-left': depth === 0 ? undefined : `24px`,
      }"
    >
      <template #activator>
        <v-list-item-icon
          v-if="drawer.type === MenuType.GROUP"
          :style="
            depth !== 0
              ? 'margin-right: 4px !important;'
              : 'margin-right: 8px !important'
          "
        >
          <v-icon class="material-symbols-outlined" v-text="drawer.icon" />
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title class="text-b-15" v-text="drawer.name" />
        </v-list-item-content>
      </template>
      <slot />
    </v-list-group>
  </div>
</template>

<script setup lang="ts">
import type { Drawer } from "@/definitions/types";
import { MenuType } from "@/definitions/selections";
import { useResetStore } from "@/stores/reset";

defineProps<{
  drawer: Drawer;
  depth: number;
}>();

const resetStore = useResetStore();

const resetComponent = () => {
  resetStore.resetComponent();
};
</script>
