import router from "@/router";
import type { UserToken, DateTime } from "@/definitions/types";
import dayjs from "dayjs";
import jwt_decode from "jwt-decode";
import { useUserStore } from "@/stores/user";
import { API_HOST } from "@/constants/envs";
import axios from "axios";
import type { Location } from "vue-router";
import { useAlertStore } from "@/stores/alert";
import { stringifyParams } from "@/utils/apis";
import { parse } from "qs";
import type { Dictionary } from "vue-router/types/router";

function formatPath(val: string): string {
  const _path = val.split("?");
  const uri = _path.splice(0, 1);
  return uri + stringifyParams(parse(_path.join("?")));
}

export async function routerPush(
  toPath: string,
  query?: Dictionary<string | (string | null)[] | null | undefined>,
): Promise<void> {
  const currentPath = router.currentRoute.fullPath;
  if (formatPath(currentPath) === formatPath(toPath)) {
    return;
  }

  await router.push({ path: toPath, query });
}
export async function routerReplace(toPath: string): Promise<void> {
  const currentPath = router.currentRoute.fullPath;
  if (formatPath(currentPath) === formatPath(toPath)) {
    return;
  }
  await router.replace(toPath);
}

export async function routerPushWithQuery(
  toPath: string | Location,
): Promise<void> {
  const currentPath = router.currentRoute.fullPath;
  if (currentPath !== toPath) {
    await router.push(toPath);
  }
}

export async function goSignInPage(): Promise<void> {
  const { clearUser } = useUserStore();
  clearUser();
  await routerReplace("/sign-in");
}

export async function goLandingPage(): Promise<void> {
  const clearProfile = () => window.localStorage.removeItem("X-Profile-Id");
  const { clearUser } = useUserStore();

  clearUser();
  clearProfile();

  await routerReplace("/");
}

export async function getNewToken(): Promise<
  | {
      accessToken: string;
      refreshToken: string;
    }
  | undefined
> {
  try {
    const response = await axios
      .create({
        baseURL: API_HOST,
        headers: {
          contentType: "application/json",
          AuthorizationR: await getValidatedRefreshToken(),
        },
      })
      .get<UserToken>("api/v1/users/token/refresh");
    return response.data;
  } catch (e: unknown) {
    if (axios.isAxiosError(e)) {
      const statusCode = e.response?.status || 500;
      if (statusCode === 401 || e.message === "Invalid token specified!") {
        await goSignInPage();
      } else if ([403, 404, 500].includes(statusCode)) {
        const { toastError } = useAlertStore();
        toastError(e.message);
      } else {
        const { toastError } = useAlertStore();
        console.warn(`Missing Status Code: ${statusCode}`);
        toastError(e.message);
      }
    } else {
      console.error(e);
    }
  }
}

export async function signOut(): Promise<void> {
  try {
    await axios.delete(`${API_HOST}api/v1/users/sign-out`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: await getValidatedAccessToken(),
      },
    });
  } catch (e) {
    console.error(e);
  }
  await goLandingPage();
}

export function isExpiredToken(token): boolean {
  return dayjs((jwt_decode(token) as { exp: number }).exp * 1000).isBefore(
    dayjs(),
  );
}

export async function getValidatedAccessToken(): Promise<string> {
  let accessToken = window.localStorage.getItem("accessToken");
  if (!accessToken) {
    await goSignInPage();
    return "";
  }

  try {
    if (isExpiredToken(accessToken)) {
      const { reIssueAccessToken } = useUserStore();
      await reIssueAccessToken();
      accessToken = window.localStorage.getItem("accessToken");
    }
  } catch (e: unknown) {
    await signOut();
  }
  return accessToken ?? "";
}

export async function getValidatedRefreshToken(): Promise<string> {
  const refreshToken = window.localStorage.getItem("refreshToken");
  if (!refreshToken || isExpiredToken(refreshToken)) {
    await goSignInPage();
  }
  return refreshToken ?? "";
}

export async function isValidTokens(): Promise<boolean> {
  const accessToken = window.localStorage.getItem("accessToken");
  const refreshToken = window.localStorage.getItem("refreshToken");

  if (!accessToken) {
    return false;
  }

  try {
    if (!isExpiredToken(accessToken)) {
      return true;
    }

    if (!refreshToken || isExpiredToken(refreshToken)) {
      throw Error("로그인 상태가 만료되었습니다. 다시 로그인을 진행해주세요.");
    } else {
      const { reIssueAccessToken } = useUserStore();
      await reIssueAccessToken();
      return true;
    }
  } catch (e: unknown) {
    if (e instanceof Error) {
      console.error(e.message);
    }
    return false;
  }
}

export function greaterThanNow(time: DateTime): boolean {
  const now = dayjs();
  return dayjs(time).isAfter(now);
}
export function isValidMinuteTimeFormat(value: string): boolean {
  return value
    .split(":")
    .every((time, index) => +time >= 0 && +time < (index === 0 ? 24 : 60));
}
