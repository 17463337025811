<template>
  <v-dialog :value="isOpened" width="auto" @click:outside="handleClickClose()">
    <v-card
      style="
        display: flex;
        width: 900px;
        height: 800px;
        flex-direction: column;
        align-items: center;
        background: var(--Background-backgroundPrimary, #fff);
        box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.04),
          0px 20px 50px 0px rgba(34, 34, 34, 0.12);
      "
    >
      <div
        style="
          display: flex;
          padding: 16px;
          justify-content: flex-end;
          width: 100%;
        "
      >
        <v-btn icon @click.stop="handleClickClose">
          <v-icon size="x-large" style="color: #8d96a1">mdi-close</v-icon>
        </v-btn>
      </div>

      <div
        style="
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          background-image: url('../src/assets/images/landing/migration_modal_background.png');
          background-size: cover;
          background-position: center;
          padding: 40px 50px 0px 50px;
        "
      >
        <div
          style="
            display: flex;
            gap: 16px;
            flex-direction: column;
            justify-content: center;
            align-items: center;
          "
        >
          <div
            style="
              display: flex;
              flex-direction: column;
              justify-content: center;
              gap: 4px;
            "
          >
            <p
              style="
                font-family: Poppins;
                margin-bottom: 0;
                text-align: center;
                white-space: pre-line;
                color: #00416f;
                font-size: 36px;
                font-style: normal;
                font-weight: 700;
                line-height: 40px; /* 111.111% */
                vertical-align: middle;
              "
            >
              {{ $t("newLanding.modal.title") }}
            </p>
            <div
              style="
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 4px;
                height: 36px;
              "
            >
              <p
                style="
                  font-family: Poppins;
                  color: #00416f;
                  font-size: 32px;
                  font-weight: 700;
                  line-height: 40px;
                  margin-bottom: 0;
                "
              >
                NEW
              </p>
              <img
                src="@/assets/images/logo/logo_blue.png"
                alt="logo"
                width="122px"
                height="36px"
              />
              <p
                style="
                  font-family: Poppins;
                  color: #00416f;
                  font-size: 32px;
                  font-weight: 700;
                  line-height: 40px;
                  margin-bottom: 0;
                "
              >
                !
              </p>
              <div
                style="
                  display: flex;
                  align-items: center;
                  width: fit-content;
                  height: 20px;
                  padding: 0px 4px;
                  border-radius: 4px;
                  background: #8d96a1;
                  color: #fff;

                  font-size: 12px;
                  font-weight: 600;
                "
              >
                <p style="margin-bottom: 0px">BETA</p>
              </div>
            </div>
          </div>
          <p
            v-dompurify-html="$t('newLanding.modal.description.phase1')"
            class="description"
          />
          <p
            v-dompurify-html="$t('newLanding.modal.description.phase2')"
            class="description"
          />
          <div class="serviceDateDescription">
            <div>
              <p
                style="
                  color: var(--Content-secondary, #3c4651);
                  font-size: 14px;
                  font-weight: 700;
                "
              >
                {{ $t("newLanding.modal.description.serviceDate.beta.title") }}
              </p>
              <p
                style="
                  color: var(--Content-secondary, #3c4651);
                  font-size: 14px;
                  font-weight: 500;
                "
              >
                {{
                  $t(
                    "newLanding.modal.description.serviceDate.beta.description",
                  )
                }}
              </p>
            </div>
            <div>
              <p
                style="
                  color: var(--Content-secondary, #3c4651);
                  font-size: 14px;
                  font-weight: 700;
                "
              >
                {{
                  $t("newLanding.modal.description.serviceDate.official.title")
                }}
              </p>
              <p style="color: #dc362e; font-size: 14px; font-weight: 500">
                {{
                  $t(
                    "newLanding.modal.description.serviceDate.official.description",
                  )
                }}
              </p>
            </div>
          </div>

          <v-btn
            class="button"
            elevation="0"
            color="#242a30"
            @click="pushExternalLink()"
          >
            <p
              style="
                margin-bottom: 0;
                font-family: Pretendard;
                color: #fff;
                font-size: 14px;
                font-weight: 700;
              "
            >
              {{ $t("newLanding.modal.button") }}
            </p>
            <v-icon size="medium" style="margin-left: 6px; color: #fff"
              >mdi-open-in-new</v-icon
            >
          </v-btn>
        </div>
      </div>
      <img
        src="@/assets/images/landing/migration_example_image.png"
        style="
          width: 720px;
          height: 296px;
          border-radius: 0px;
          object-fit: cover;
          position: absolute;
          bottom: 0;
          z-index: 10;
        "
      />
    </v-card>
  </v-dialog>
</template>

<script setup lang="ts">
import {
  useFirstTimeModalStore,
  useMigrationModalStore,
} from "@/stores/migration-modal";
import { storeToRefs } from "pinia";

const firstTimeModal = useFirstTimeModalStore();
const migrationModal = useMigrationModalStore();
const { isOpened } = storeToRefs(migrationModal);

const { close } = migrationModal;
const handleClickClose = () => {
  firstTimeModal.markAsViewed();
  close();
};

const pushExternalLink = () => {
  window.open("https://beta.ai-prix.com/", "_blank");
};
</script>

<style>
.description {
  font-family: Pretendard;
  margin-bottom: 0 !important;
  white-space: pre-line;
  color: #586370;
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 133.333% */
  letter-spacing: -0.25px;

  > strong {
    color: #00beca;
    font-weight: 600;
  }
}
.serviceDateDescription {
  font-family: Pretendard;
  width: 700px;
  display: flex;
  gap: 0px;
  padding: 8px 24px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background: #eff1f3;

  > div {
    display: flex;
    margin-bottom: 0;
    height: 22px;
  }
}
.button {
  height: 36px;
  margin-top: 8px;
  border-radius: 8px;
}
</style>
