<template>
  <v-list
    class="pa-0 toast-list"
    style="position: fixed; bottom: 0; z-index: 999"
  >
    <v-list-item v-for="item in items" :key="item.uuid" class="pa-0">
      <SnackbarAlert :value="item" style="position: fixed" />
    </v-list-item>
  </v-list>
</template>

<script setup lang="ts">
import { useAlertStore } from "@/stores/alert";
import { storeToRefs } from "pinia";
import SnackbarAlert from "@/views/components/dialog/SnackbarAlert.vue";

const { items } = storeToRefs(useAlertStore());
</script>
