<template>
  <v-snackbar
    :value="true"
    left
    text
    outlined
    :color="value.color"
    class="ma-5"
    :timeout="value.timer"
    @input="toastClose(value)"
  >
    <div class="d-flex align-center">
      <v-icon :color="value.color">{{ value.icon }}</v-icon>
      <!-- eslint-disable vue/no-v-html -->
      <span class="mx-2 alert-message text-r-15" v-html="value.message" />
      <v-spacer />
      <v-icon
        class="alert-close material-symbols-outlined"
        :style="{ color: value.color, fontSize: '20px' }"
        @click="toastClose(value)"
      >
        close
      </v-icon>
    </div>
  </v-snackbar>
</template>

<script setup lang="ts">
import { useAlertStore } from "@/stores/alert";
import type { Alert } from "@/definitions/types";

defineProps<{
  value: Alert;
}>();

const { toastClose } = useAlertStore();
</script>
