export default Object.freeze({
  en: {
    logout: "Logout",
    disapprovalTitle: "Please wait🙂",
    rejectionTitle: "Sorry🥲",
    endTitle: "Sorry🥲",
    disapprovalSubtitle: "Your registration application has been completed",
    rejectionSubtitle: "Your application has been put on hold.",
    endSubtitle: "Your membership registration process has been closed.",
    disapprovalContent:
      "After registration, we will send next procedure through your email.",
    rejectionContent: "Our representative will provide further guidance soon.",
    endContent: "If you need any further question, contact prix@prix.com",
    completeRegistration: "Your registration application has been completed.",
    saveBooking: "If you want to book as saved, please click Booking.",
    submitBooking: "Your Booking has been submitted.",
    saveBookingFail: "Your booking save has failed, we regret to inform you.",
    submitBookingFail:
      "We regret to inform you that your booking has been rejected.",
    pendingBookingAccessDenied: "Administrator approval is required.",
    bookingAccessDenied: "The corresponding booking record does not exist.",
    dateTimeFormat: "MM-DD-YYYY HH:mm:ss",
    passwordHint:
      "Please enter at least 8 characters in a combination of at least 3 of uppercase/lowercase letters, numbers, and symbols.",
    changeLoginIdHint:
      "* If you need to change your ID, contact the A.I.F manager.",
    failedToPdf: "Failed to generate PDF.",
    failedToUpload: "Failed to upload.",
    idHint:
      "Please enter at least 6 characters with a mix of letters, numbers and symbols.",
    versionUpdate:
      "The service version has been updated. Please refresh the page.",
    mainErrorMsg: "An unknown error has occurred.",
    subErrorMsg:
      "We apologize for the inconvenience.<br />Please contact the administrator",
    errorBtn: "Return To Previous Page",
    preAlertMsg:
      "Do you want to update the Pre-alert document?<br />The documents will be attached and emailed to the person in charge.",
    preAlertSuccess: "The Pre-alert document update has been completed.",
    invoiceValuePlaceHolder: "Please indicate invoice value in dollars($).",
  },
  ko: {
    logout: "로그아웃",
    dateTimeFormat: "YYYY년 MM월 DD일 HH시 mm분 ss초",
    passwordHint:
      "비밀번호는 알파벳 대/소문자, 숫자, 기호 중 3개 이상을 조합하여 최소 8자 이상 입력해주세요.",
    changeLoginIdHint: "* ID는 변경이 필요할 경우 A.I.F 관리자에게 문의하세요.",
    idHint: "아이디는 영문, 숫자가 포함된 조합으로 최소 6자 이상 입력해주세요.",
    versionUpdate:
      "서비스 버전이 업데이트 되었으니, 창을 새로 고치시기 바랍니다.",
    mainErrorMsg: "알 수 없는 오류가 발생했습니다.",
    subErrorMsg: "이용에 불편을 드려 죄송합니다.<br />관리자에게 문의해주세요.",
    errorBtn: "이전 화면으로",
    preAlertMsg:
      "Pre-alert 서류를 업데이트 하시겠습니까?<br />담당자 메일로 해당 서류가 첨부되어 발송됩니다.",
    preAlertSuccess: "Pre-alert 업데이트가 완료 되었습니다.",
    invoiceValuePlaceHolder: "인보이스 금액은 달러($)로 표기해주세요.",
  },
  "zh-chs": {
    dateTimeFormat: "MM-DD-YYYY HH:mm:ss",
  },
});
