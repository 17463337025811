export default Object.freeze({
  user: [
    {
      icon: "mdi-account-box-outline",
      key: "usermenu.profile",
      text: "내정보",
    },
    {
      icon: "mdi-shield-key-outline",
      key: "usermenu.changePassword",
      text: "비밀번호변경",
    },
    {
      icon: "mdi-palette",
      key: "usermenu.theme",
      text: "테마",
    },
  ],
});
