// 컴포넌트 리셋(재렌더링)시 필요한 스토어
import { defineStore } from "pinia";

export const useResetStore = defineStore("reset", {
  state: () => ({
    resetKey: 0,
  }),
  actions: {
    resetComponent() {
      this.resetKey++;
    },
  },
});
