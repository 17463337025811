import Vue from "vue";
import Vuetify from "vuetify/lib";
import en from "vuetify/src/locale/en";
import config from "../configs";
import { ENVIRONMENT } from "@/constants/envs";
import { LanguageType } from "@/definitions/selections";

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: "mdi",
  },
  lang: {
    locales: { en },
    current: LanguageType.ENGLISH,
  },
  theme: {
    dark: config.theme.globalTheme === "dark",
    themes: {
      dark: config.theme.dark,
      light: config.theme.light,
    },
    options: {
      customProperties: true,
      minifyTheme: function (css) {
        return ENVIRONMENT !== "local" ? css.replace(/[\r|\n]/g, "") : css;
      },
    },
  },
});
