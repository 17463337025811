<template>
  <v-menu offset-y transition="slide-y-transition" left>
    <template #activator="{ on }">
      <v-btn text v-on="on">
        <flag-icon :flag="convertLanguageCodeToNationalCode(language)" />
        <span
          v-show="showLabel"
          class="ml-1"
          v-text="convertLanguageCodeToNationalCode(language)"
        />
        <v-icon v-if="showArrow" right>mdi-chevron-down</v-icon>
      </v-btn>
    </template>

    <v-list dense nav>
      <v-list-item
        v-for="languageType in filteredLanguageTypes"
        :key="languageType.value"
        @click="onClickLanguage(languageType.value)"
      >
        <flag-icon
          class="mr-1"
          :flag="convertLanguageCodeToNationalCode(languageType.value)"
        />
        <v-list-item-title v-text="languageType.text" />
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script setup lang="ts">
import FlagIcon from "@/components/toolbar/FlagIcon.vue";
import {
  LANGUAGE_TYPE,
  LanguageType,
  type LanguageTypeString,
} from "@/definitions/selections";
import { useLanguageStore } from "@/stores/language";
import { storeToRefs } from "pinia";
import { computed } from "vue";
import { useRoute } from "vue-router/composables";

defineProps<{
  showArrow?: boolean;
  showLabel?: boolean;
}>();

const { language } = storeToRefs(useLanguageStore());
const route = useRoute();

function convertLanguageCodeToNationalCode(languageCode: LanguageTypeString) {
  switch (languageCode) {
    case LanguageType.ENGLISH:
      return "us";
    case LanguageType.KOREAN:
      return "kr";
    case LanguageType.CHINESE:
      return "cn";
    default:
      return languageCode;
  }
}

const filteredLanguageTypes = computed(() => {
  if (route.path.includes("/board/")) {
    return LANGUAGE_TYPE.filter(
      (language) => language.value !== LanguageType.ENGLISH,
    );
  }
  return LANGUAGE_TYPE;
});

function onClickLanguage(languageCode: LanguageTypeString) {
  language.value = languageCode;
  window.location.reload();
}
</script>
