import { defineStore } from "pinia";
import type { Confirm } from "@/definitions/types";
import i18n from "@/plugins/vue-i18n";

export const useConfirmStore = defineStore("confirm", {
  state: () => {
    return {
      items: [] as Confirm[],
    };
  },
  actions: {
    confirmUnitChange(callbackConfirm: CallableFunction, country: string) {
      if (country === "us") {
        this.pushConfirm(i18n.t("quotation.usUnitChange"), callbackConfirm);
      } else
        this.pushConfirm(i18n.t("quotation.othersUnitChange"), callbackConfirm);
    },
    confirmRequest(callbackConfirm: CallableFunction) {
      this.pushConfirm(
        i18n.t("quotation.confirmPopupContent"),
        callbackConfirm,
      );
    },
    confirmPOLchange(
      callbackConfirm: CallableFunction,
      pol: string,
      polChange?: string,
    ) {
      if (pol === "BRU") {
        this.pushConfirm(
          i18n.t("quotation.polBRU", { polChange }),
          callbackConfirm,
          undefined,
          undefined,
          "",
        );
      } else if (pol === "DEBRV") {
        this.pushConfirm(
          i18n.t("quotation.polDEBRV"),
          callbackConfirm,
          undefined,
          undefined,
          "",
        );
      }
    },
    confirmQuotationDisable(callbackConfirm: CallableFunction) {
      this.pushConfirm(
        i18n.t("quotation.quotationRequestDisable"),
        callbackConfirm,
      );
    },
    confirmCreate(callbackConfirm: CallableFunction) {
      this.pushConfirm("등록하시겠습니까?", callbackConfirm);
    },

    confirmUpdate(callbackConfirm: CallableFunction) {
      this.pushConfirm("수정하시겠습니까?", callbackConfirm);
    },
    confirmDelete(callbackConfirm: CallableFunction) {
      this.pushConfirm("삭제하시겠습니까?", callbackConfirm);
    },
    pushConfirm(
      content,
      callbackConfirm: CallableFunction,
      width = "450px",
      confirmButtonTitle = i18n.t("quotation.confirm") as string,
      cancelButtonTitle = i18n.t("quotation.cancel") as string,
    ) {
      this.pushConfirmWithCallbackCancel(
        content,
        callbackConfirm,
        undefined,
        width,
        confirmButtonTitle,
        cancelButtonTitle,
      );
    },
    pushConfirmWithCallbackCancel(
      content,
      callbackConfirm: CallableFunction,
      callbackCancel?: CallableFunction,
      width = "450px",
      confirmButtonTitle = i18n.t("quotation.confirm") as string,
      cancelButtonTitle = i18n.t("quotation.cancel") as string,
    ) {
      this.$state.items = [
        ...this.$state.items,
        {
          content,
          width,
          cancelButtonTitle,
          confirmButtonTitle,
          callbackConfirm,
          callbackCancel,
        },
      ];
    },
    okConfirm() {
      const item = this.$state.items.shift();
      item?.callbackConfirm && item.callbackConfirm();
      this.$state.items.splice(0, 1);
    },
    closeConfirm() {
      const item = this.$state.items.shift();
      item?.callbackCancel && item.callbackCancel();
      this.$state.items.splice(0, 1);
    },
    unavailableUsQuotation(callbackConfirm?: CallableFunction) {
      this.pushConfirm(
        "We regret to inform you that we are no longer handling business from the U.S.",
        () => callbackConfirm?.(),
        "450px",
        "Confirm",
        "",
      );
    },
  },
});
