import type { SelectItem } from "@/definitions/types";
import type { AuthorityTypeString } from "@/definitions/authorities";
import { AuthorityType } from "@/definitions/authorities";

export const MenuType = {
  GROUP: "GROUP",
  PAGE: "PAGE",
  NEW_TAB: "NEW_TAB",
} as const;
export type MenuTypesString = (typeof MenuType)[keyof typeof MenuType];
export const BOOLEAN_TYPE: SelectItem<boolean>[] = [
  { value: true, text: "예" },
  { value: false, text: "아니요" },
];
export const CheckboxStatus = {
  TRUE: "TRUE",
  FALSE: "FALSE",
  INTERMEDIATE: "INTERMEDIATE",
};

export const AUTHORITY_TYPE: SelectItem<AuthorityTypeString>[] = [
  {
    value: AuthorityType.CREATE,
    text: "생성",
  },
  { value: AuthorityType.EDIT, text: "수정" },
  {
    value: AuthorityType.DELETE,
    text: "삭제",
  },
];

export const SignUpCountryType = {
  KR: "KR",
  CN: "CN",
} as const;
export type SignUpCountryTypeString =
  (typeof SignUpCountryType)[keyof typeof SignUpCountryType];
export const SIGN_UP_COUNTRY_SELECT_ITEMS: SelectItem<SignUpCountryTypeString>[] =
  [
    { value: SignUpCountryType.KR, text: "KOREA" },
    { value: SignUpCountryType.CN, text: "CHINA" },
  ];

export const SignUpLanguageType = {
  English: "en",
  Chinese: "zh-chs",
  Korean: "ko",
  Deutsch: "de",
  Francais: "fr",
  Italian: "it",
  Japanese: "ja",
} as const;
export type SignUpLanguageTypeString =
  (typeof SignUpLanguageType)[keyof typeof SignUpLanguageType];
export const SIGN_UP_LANGUAGE_SELECT_ITEMS: SelectItem<SignUpLanguageTypeString>[] =
  [
    { value: SignUpLanguageType.English, text: "ENGLISH" },
    { value: SignUpLanguageType.Chinese, text: "中文" },
    { value: SignUpLanguageType.Korean, text: "한국어" },
    { value: SignUpLanguageType.Deutsch, text: "Deutsch" },
    { value: SignUpLanguageType.Francais, text: "Francais" },
    { value: SignUpLanguageType.Italian, text: "Italian" },
    { value: SignUpLanguageType.Japanese, text: "日本語" },
  ];

export const CustomsClearanceType = {
  GENERAL: "G",
  RETURN_CARGO: "RC",
  CARNET: "CARNET",
  CITES: "CITES",
} as const;
export type CustomsClearanceTypeString =
  (typeof CustomsClearanceType)[keyof typeof CustomsClearanceType];
export const CUSTOMS_SELECT_ITEMS: SelectItem<CustomsClearanceTypeString>[] = [
  { value: CustomsClearanceType.GENERAL, text: "GENERAL" },
  {
    value: CustomsClearanceType.RETURN_CARGO,
    text: "RETURN CARGO (REPAIR, TEST ETC)",
  },
  { value: CustomsClearanceType.CARNET, text: "CARNET" },
  { value: CustomsClearanceType.CITES, text: "CITES" },
];

export const ItemType = {
  LPG: "LPG",
  ART: "ART",
  OTHER: "OTH",
  BC: "BC",
} as const;
export type ItemTypeString = (typeof ItemType)[keyof typeof ItemType];
export const ITEM_SELECT_ITEMS: SelectItem<ItemTypeString>[] = [
  { value: ItemType.OTHER, text: "Other" },
  { value: ItemType.LPG, text: "Liquid / Powder / Granulate" },
  { value: ItemType.ART, text: "Furniture / Art" },
  { value: ItemType.BC, text: "Batteries, Chemical Product" },
];

export const USItemType = {
  OTHER: "OTH",
  POWDER: "POWDER",
  BDPC: "BDPC",
  ART: "ART",
} as const;
export type USItemTypeString = (typeof USItemType)[keyof typeof USItemType];
export const US_ITEM_SELECT_ITEMS: SelectItem<USItemTypeString>[] = [
  { value: USItemType.OTHER, text: "Other" },
  { value: USItemType.POWDER, text: "Powder" },
  { value: USItemType.BDPC, text: "Barrel/Drum/Pail/Can packaged goods" },
  { value: USItemType.ART, text: "Art" },
];

// modeOfTransport=FCL & POL(BEANR, FRFOS, FRLEH) & POD(KRPUS) & typeOfCargo=RF 일경우 item항목이 추가
export const ReeferItemType = {
  FOOD: "FOOD",
  WINE: "WINE",
  PHARMA: "PH",
  ETC: "ETC",
} as const;
export type ReeferItemTypeString =
  (typeof ReeferItemType)[keyof typeof ReeferItemType];
export const REEFER_ITEM_SELECT_ITEMS: SelectItem<ReeferItemTypeString>[] = [
  { value: ReeferItemType.FOOD, text: "FOOD STUFF" },
  { value: ReeferItemType.WINE, text: "WINE" },
  { value: ReeferItemType.PHARMA, text: "PHARMA" },
  { value: ReeferItemType.ETC, text: "ETC" },
];

export const ModeOfTransportType = {
  AIR: "AIR",
  LCL: "LCL",
  FCL: "FCL",
  CONSOL: "CONSOL",
} as const;
export type ModeOfTransportString =
  (typeof ModeOfTransportType)[keyof typeof ModeOfTransportType];
export const MODE_OF_TRANSPORT_SELECT_ITEMS: SelectItem<ModeOfTransportString>[] =
  [
    { value: ModeOfTransportType.AIR, text: "AIR" },
    { value: ModeOfTransportType.LCL, text: "LCL" },
    { value: ModeOfTransportType.FCL, text: "FCL" },
  ];
export const BOOKING_MODE_OF_TRANSPORT_SELECT_ITEMS: SelectItem<ModeOfTransportString>[] =
  [
    { value: ModeOfTransportType.AIR, text: "AIR" },
    { value: ModeOfTransportType.LCL, text: "LCL" },
    { value: ModeOfTransportType.FCL, text: "FCL" },
    { value: ModeOfTransportType.CONSOL, text: "CONSOL" },
  ];

export const PortOfLoadingType = {
  // TODO: 🚧 추가 옵션 확인 필요 '기획 문의'
  FRA: "FRA",
  MXP: "MXP",
  CDG: "CDG",
  AMS: "AMS",
} as const;
export type PortOfLoadingString =
  (typeof PortOfLoadingType)[keyof typeof PortOfLoadingType];
export const PORT_OF_LOADING_SELECT_ITEMS: SelectItem<PortOfLoadingString>[] = [
  { value: PortOfLoadingType.FRA, text: "FRA" },
  { value: PortOfLoadingType.MXP, text: "MXP" },
  { value: PortOfLoadingType.CDG, text: "CDG" },
  { value: PortOfLoadingType.AMS, text: "AMS" },
];

export const PortOfDischargeType = {
  // TODO: 🚧 추가 옵션 확인 필요 '기획 문의'
  ICN: "ICN",
  PVG: "PVG",
  PEK: "PEK",
  CAN: "CAN",
  CGQ: "CGQ",
  CKG: "CKG",
  DLC: "DLC",
  HFE: "HFE",
  HGH: "HGH",
  KIX: "KIX",
  NGB: "NGB",
  NKG: "NKG",
  NRT: "NRT",
  SHE: "SHE",
  SZV: "SZV",
  SZX: "SZX",
  TAO: "TAO",
  TPE: "TPE",
  TSN: "TSN",
  WEH: "WEH",
  WNZ: "WNZ",
  WUH: "WUH",
  XIY: "XIY",
  YNT: "YNT",
};
export type PortOfDischargeString =
  (typeof PortOfDischargeType)[keyof typeof PortOfDischargeType];
export const PORT_OF_DISCHARGING_SELECT_ITEMS: SelectItem<PortOfDischargeString>[] =
  [
    { value: PortOfDischargeType.ICN, text: "ICN" },
    { value: PortOfDischargeType.PVG, text: "PVG" },
    { value: PortOfDischargeType.PEK, text: "PEK" },
    { value: PortOfDischargeType.CAN, text: "CAN" },
    { value: PortOfDischargeType.CGQ, text: "CGQ" },
    { value: PortOfDischargeType.CKG, text: "CKG" },
    { value: PortOfDischargeType.DLC, text: "DLC" },
    { value: PortOfDischargeType.HFE, text: "HFE" },
    { value: PortOfDischargeType.HGH, text: "HGH" },
    { value: PortOfDischargeType.KIX, text: "KIX" },
    { value: PortOfDischargeType.NGB, text: "NGB" },
    { value: PortOfDischargeType.NKG, text: "NKG" },
    { value: PortOfDischargeType.NRT, text: "NRT" },
    { value: PortOfDischargeType.SHE, text: "SHE" },
    { value: PortOfDischargeType.SZV, text: "SZV" },
    { value: PortOfDischargeType.SZX, text: "SZX" },
    { value: PortOfDischargeType.TAO, text: "TAO" },
    { value: PortOfDischargeType.TPE, text: "TPE" },
    { value: PortOfDischargeType.TSN, text: "TSN" },
    { value: PortOfDischargeType.WEH, text: "WEH" },
    { value: PortOfDischargeType.WNZ, text: "WNZ" },
    { value: PortOfDischargeType.WUH, text: "WUH" },
    { value: PortOfDischargeType.XIY, text: "XIY" },
    { value: PortOfDischargeType.YNT, text: "YNT" },
  ];

export const AirTypeOfCargoType = {
  GENERAL: "GENERAL",
  D: "D",
  PERISHABLE: "PERISHABLE",
} as const;
export type AirTypeOfCargoString =
  (typeof AirTypeOfCargoType)[keyof typeof AirTypeOfCargoType];
export const AIR_TYPE_OF_CARGO_SELECT_ITEMS: SelectItem<AirTypeOfCargoString>[] =
  [
    { value: AirTypeOfCargoType.GENERAL, text: "GENERAL" },
    { value: AirTypeOfCargoType.D, text: "DG" },
    { value: AirTypeOfCargoType.PERISHABLE, text: "PERISHABLE" },
  ];

export const OceanTypeOfCargoType = {
  NORMAL: "NORMAL",
  DG: "DG",
  REFRIGERATED: "REFRIGERATED",
} as const;
export type OceanTypeOfCargoString =
  (typeof OceanTypeOfCargoType)[keyof typeof OceanTypeOfCargoType];
export const OCEAN_TYPE_OF_CARGO_SELECT_ITEMS: SelectItem<OceanTypeOfCargoString>[] =
  [
    { value: OceanTypeOfCargoType.NORMAL, text: "NORMAL" },
    { value: OceanTypeOfCargoType.DG, text: "DG" },
    { value: OceanTypeOfCargoType.REFRIGERATED, text: "REFRIGERATED" },
  ];

export const StackableType = {
  STACKABLE: "STACKABLE",
  NONSTACKABLE: "NON_STACKABLE",
} as const;
export type StackableString =
  (typeof StackableType)[keyof typeof StackableType];
export const STACKABLE_SELECT_ITEMS: SelectItem<StackableString>[] = [
  { value: StackableType.STACKABLE, text: "STACKABLE" },
  { value: StackableType.NONSTACKABLE, text: "NON-STACKABLE" },
];

export const IncotermsType = {
  EXW: "EXW",
  FOB: "FOB",
  FCA: "FCA",
  FCAFACTORY: "FCA_FACTORY",
} as const;
export type IncotermsString =
  (typeof IncotermsType)[keyof typeof IncotermsType];
export const INCOTERMS_SELECT_ITEMS: SelectItem<IncotermsString>[] = [
  { value: IncotermsType.EXW, text: "EXW" },
  { value: IncotermsType.FOB, text: "FOB" },
  { value: IncotermsType.FCA, text: "FCA" },
  { value: IncotermsType.FCAFACTORY, text: "FCA-FACTORY" },
];

export const CountryOfOriginType = {
  GERMANY: "GERMANY",
  FRANCE: "FRANCE",
  ITALY: "ITALY",
  DENMARK: "DENMARK",
  NETHERLANDS: "NETHERLANDS",
  SWISS: "SWISS",
  CZECH: "CZECH",
  HUNGARY: "HUNGARY",
  AUSTRIA: "AUSTRIA",
  POLAND: "POLAND",
  BELGIUM: "BELGIUM",
  FINLAND: "FINLAND",
  SWEDEN: "SWEDEN",
  NORWAY: "NORWAY",
  JAPAN: "JAPAN",
  USA: "USA",
};
export type CountryOfOriginString =
  (typeof CountryOfOriginType)[keyof typeof CountryOfOriginType];
export const COUNTRY_OF_ORIGIN_SELECT_ITMES: SelectItem<CountryOfOriginString>[] =
  [
    { value: CountryOfOriginType.GERMANY, text: "GERMANY" },
    { value: CountryOfOriginType.FRANCE, text: "FRANCE" },
    { value: CountryOfOriginType.ITALY, text: "ITALY" },
    { value: CountryOfOriginType.DENMARK, text: "DENMARK" },
    { value: CountryOfOriginType.NETHERLANDS, text: "NETHERLANDS" },
    { value: CountryOfOriginType.SWISS, text: "SWISS" },
    { value: CountryOfOriginType.CZECH, text: "CZECH" },
    { value: CountryOfOriginType.HUNGARY, text: "HUNGARY" },
    { value: CountryOfOriginType.AUSTRIA, text: "AUSTRIA" },
    { value: CountryOfOriginType.POLAND, text: "POLAND" },
    { value: CountryOfOriginType.BELGIUM, text: "BELGIUM" },
    { value: CountryOfOriginType.FINLAND, text: "FINLAND" },
    { value: CountryOfOriginType.SWEDEN, text: "SWEDEN" },
    { value: CountryOfOriginType.NORWAY, text: "NORWAY" },
    { value: CountryOfOriginType.JAPAN, text: "JAPAN" },
    { value: CountryOfOriginType.USA, text: "USA" },
  ];

export const CntrTypeType = {
  DRY: "DRY",
  OPENTOP: "OPEN_TOP",
  FLATRACK: "FLAT_RACK",
  REEFER: "REEFER",
} as const;
export type CntrTypeString = (typeof CntrTypeType)[keyof typeof CntrTypeType];
export const CNTR_TYPE_SELECT_ITEMS: SelectItem<CntrTypeString>[] = [
  { value: CntrTypeType.DRY, text: "DRY" },
  { value: CntrTypeType.OPENTOP, text: "OPEN TOP" },
  { value: CntrTypeType.FLATRACK, text: "FLAT RACK" },
  { value: CntrTypeType.REEFER, text: "REEFER" },
];
export const NORMAL_CNTR_TYPE_SELECT_ITEMS: SelectItem<CntrTypeString>[] = [
  { value: CntrTypeType.DRY, text: "DRY" },
  { value: CntrTypeType.OPENTOP, text: "OPEN TOP" },
  { value: CntrTypeType.FLATRACK, text: "FLAT RACK" },
];
export const REEFER_CNTR_TYPE_SELECT_ITEMS: SelectItem<CntrTypeString>[] = [
  { value: CntrTypeType.REEFER, text: "REEFER" },
];
export const DG_CNTR_TYPE_SELECT_ITEMS: SelectItem<CntrTypeString>[] = [
  { value: CntrTypeType.DRY, text: "DRY" },
  { value: CntrTypeType.OPENTOP, text: "OPEN TOP" },
  { value: CntrTypeType.FLATRACK, text: "FLAT RACK" },
  { value: CntrTypeType.REEFER, text: "REEFER" },
];

export const DryCntrSizeType = {
  TwentyFT: "20GP",
  FortyFT: "40GP",
  FortyHQ: "40HC",
} as const;
export type DryCntrSizeString =
  (typeof DryCntrSizeType)[keyof typeof DryCntrSizeType];
export const DRY_CNTR_SIZE_SELECT_ITEMS: SelectItem<DryCntrSizeString>[] = [
  { value: DryCntrSizeType.TwentyFT, text: "20 FT" },
  { value: DryCntrSizeType.FortyFT, text: "40 FT" },
  { value: DryCntrSizeType.FortyHQ, text: "40 HQ" },
];

export const OpenTopCntrSizeType = {
  TwentyOT: "20OT",
  FortyOT: "40OT",
  TwentyOTOverHigh: "20OT_OH",
  FortyOTOverHigh: "40OT_OH",
} as const;
export type OpenTopCntrSizeString =
  (typeof OpenTopCntrSizeType)[keyof typeof OpenTopCntrSizeType];
export const OPEN_TOP_CNTR_SIZE_SELECT_ITEMS: SelectItem<OpenTopCntrSizeString>[] =
  [
    { value: OpenTopCntrSizeType.TwentyOT, text: "20 OT" },
    { value: OpenTopCntrSizeType.FortyOT, text: "40 OT" },
    { value: OpenTopCntrSizeType.TwentyOTOverHigh, text: "20 OT OVER HIGH" },
    { value: OpenTopCntrSizeType.FortyOTOverHigh, text: "40 OT OVER HIGH" },
  ];

export const FlatRackCntrSizeType = {
  TwentyFR: "20FR",
  FortyFR: "40FR",
  TwentyFROverHigh: "20FR_OH",
  FortyFROverHigh: "40FR_OH",
  TwentyFROverWidth: "20FR_OW",
  FortyFROverWidth: "40FR_OW",
  TwentyFROverHighAndWidth: "20FR_OHW",
  FortyFROverHighAndWidth: "40FR_OHW",
} as const;
export type FlatRackCntrSizeString =
  (typeof FlatRackCntrSizeType)[keyof typeof FlatRackCntrSizeType];
export const FLAT_RACK_CNTR_SIZE_SELECT_ITEMS: SelectItem<FlatRackCntrSizeString>[] =
  [
    { value: FlatRackCntrSizeType.TwentyFR, text: "20 FR" },
    { value: FlatRackCntrSizeType.FortyFR, text: "40 FR" },
    { value: FlatRackCntrSizeType.TwentyFROverHigh, text: "20 FR OVER HIGH" },
    { value: FlatRackCntrSizeType.FortyFROverHigh, text: "40 FR OVER HIGH" },
    { value: FlatRackCntrSizeType.TwentyFROverWidth, text: "20 FR OVER WIDTH" },
    { value: FlatRackCntrSizeType.FortyFROverWidth, text: "40 FR OVER WIDTH" },
    {
      value: FlatRackCntrSizeType.TwentyFROverHighAndWidth,
      text: "20 FR OVER HIGH & WIDTH",
    },
    {
      value: FlatRackCntrSizeType.FortyFROverHighAndWidth,
      text: "40 FR OVER HIGH & WIDTH",
    },
  ];

export const ReeferCntrSizeType = {
  TwentyRF: "20RF",
  FortyRF: "40RF",
} as const;
export type ReeferCntrSizeString =
  (typeof ReeferCntrSizeType)[keyof typeof ReeferCntrSizeType];
export const REEFER_CNTR_SIZE_SELECT_ITEMS: SelectItem<ReeferCntrSizeString>[] =
  [
    { value: ReeferCntrSizeType.TwentyRF, text: "20 RF" },
    { value: ReeferCntrSizeType.FortyRF, text: "40 RF" },
  ];

export const LanguageType = {
  ENGLISH: "en",
  KOREAN: "ko",
  CHINESE: "zh-chs", // 중국어(간체)	zh-chs	중국어(번체)	zh-cht
} as const;
export type LanguageTypeString =
  (typeof LanguageType)[keyof typeof LanguageType];
export const LANGUAGE_TYPE: SelectItem<LanguageTypeString>[] = [
  { value: LanguageType.ENGLISH, text: "ENGLISH" },
  { value: LanguageType.KOREAN, text: "한국어" },
  { value: LanguageType.CHINESE, text: "中文" },
];

export const AclType = {
  PRIVATE: "private",
  PUBLIC: "public-read",
};
export type AclTypeString = (typeof AclType)[keyof typeof AclType];
export const ProjectType = {
  AI_PRIX: "AI_PRIX",
  AIF: "aif",
};
export type ProjectTypeString = (typeof ProjectType)[keyof typeof ProjectType];

export const CategoryType = {
  general: "general",
  quotation: "quotation",
  booking: "booking",
  germanyFaq: "germany-faq",
  italyFaq: "italy-faq",
  franceFaq: "france-faq",
  netherlandsFaq: "netherlands-faq",
  nordicFaq: "nordic-faq",
};
export type CategoryTypeString =
  (typeof CategoryType)[keyof typeof CategoryType];
export const CATEGORY_SELECT_ITEMS: SelectItem<CategoryTypeString>[] = [
  { value: CategoryType.general, text: "General" },
  { value: CategoryType.quotation, text: "Quotation" },
  { value: CategoryType.booking, text: "Booking" },
  { value: CategoryType.germanyFaq, text: "Germany" },
  { value: CategoryType.nordicFaq, text: "Nordic" },
  { value: CategoryType.franceFaq, text: "France" },
  { value: CategoryType.italyFaq, text: "Italy" },
  { value: CategoryType.netherlandsFaq, text: "Netherlands" },
];

export const ShippingStatusType = {
  orderCreation: "ynOrderCreate",
  shipperContact: "ynShipperContact",
  shipmentScheduleNotified: "ynScheduleNotify",
  bookingConfirmed: "ynBookingConfirm",
  warehouseEntryCompleted: "ynWarehouseEntry",
  customsClearance: "ynCustomsClearance",
  forwardShippingDocuments: "ynShippingDocument",
  departureCompleted: "departureCompleted",
  arrivalCompleted: "arrivalCompleted",
};
export type ShippingStatusTypeString =
  (typeof ShippingStatusType)[keyof typeof ShippingStatusType];
export const SHIPPING_STATUS_SELECT_ITEMS: SelectItem<ShippingStatusTypeString>[] =
  [
    // { value: ShippingStatusType.orderCreation, text: "Order Creation" },
    { value: ShippingStatusType.shipperContact, text: "Shipper Contact" },
    {
      value: ShippingStatusType.shipmentScheduleNotified,
      text: "Shipment Schedule Notified",
    },
    { value: ShippingStatusType.bookingConfirmed, text: "Booking Confirmed" },
    { value: ShippingStatusType.customsClearance, text: "Customs Clearance" },
    {
      value: ShippingStatusType.warehouseEntryCompleted,
      text: "Warehouse Entry",
    },
    {
      value: ShippingStatusType.forwardShippingDocuments,
      text: "Forward Shipping Documents",
    },
    {
      value: ShippingStatusType.departureCompleted,
      text: "Departure Completed",
    },
    { value: ShippingStatusType.arrivalCompleted, text: "Arrival Completed" },
  ];

export const TruckingShippingStatusType = {
  beforePickup: "beforePickup",
  onTheWay: "onTheWay",
  delivered: "delivered",
};
export type TruckingShippingStatusString =
  (typeof TruckingShippingStatusType)[keyof typeof TruckingShippingStatusType];
export const TRUCKING_SHIPPING_STATUS_ITEMS: SelectItem<TruckingShippingStatusString>[] =
  [
    { value: TruckingShippingStatusType.beforePickup, text: "Before Pickup" },
    { value: TruckingShippingStatusType.onTheWay, text: "On The Way" },
    { value: TruckingShippingStatusType.delivered, text: "Delivered" },
  ];

export const ShippingType = {
  all: "ALL",
  sea: "S",
  air: "A",
};
export type ShippingTypeString =
  (typeof ShippingType)[keyof typeof ShippingType];
export const SHIPPING_TYPE_SELECT_ITEMS: SelectItem<ShippingTypeString>[] = [
  { value: ShippingType.sea, text: "Sea", icon: "mdi-ferry" },
  {
    value: ShippingType.air,
    text: "Air",
    icon: "mdi-airplane",
  },
];

export const StateType = {
  WA: "WA",
  OR: "OR",
  ID: "ID",
  MT: "MT",
  WY: "WY",
  CA: "CA",
  NV: "NV",
  UT: "UT",
  CO: "CO",
  AZ: "AZ",
  NM: "NM",
  TX: "TX",
  ND: "ND",
  SD: "SD",
  NE: "NE",
  KS: "KS",
  OK: "OK",
  MN: "MN",
  IA: "IA",
  MO: "MO",
  AR: "AR",
  LA: "LA",
  WI: "WI",
  IL: "IL",
  MI: "MI",
  IN: "IN",
  OH: "OH",
  KY: "KY",
  TN: "TN",
  MS: "MS",
  AL: "AL",
  GA: "GA",
  SC: "SC",
  FL: "FL",
  ME: "ME",
  NH: "NH",
  VT: "VT",
  NY: "NY",
  MA: "MA",
  RI: "RI",
  CT: "CT",
  PA: "PA",
  NJ: "NJ",
  DE: "DE",
  MD: "MD",
  WV: "WV",
  VA: "VA",
  NC: "NC",
};
export type StateTypeString = (typeof StateType)[keyof typeof StateType];

export const ExportCountryOfOriginType = {
  KOREA: "KOREA",
  CHINA: "CHINA",
  ETC: "ETC",
};
export type ExportCountryOfOriginString =
  (typeof ExportCountryOfOriginType)[keyof typeof ExportCountryOfOriginType];

export const EXPORT_COUNTRY_OF_ORIGIN_SELECT_ITEMS: SelectItem<ExportCountryOfOriginString>[] =
  [
    { value: ExportCountryOfOriginType.KOREA, text: "KOREA" },
    { value: ExportCountryOfOriginType.CHINA, text: "CHINA" },
    { value: ExportCountryOfOriginType.ETC, text: "ETC" },
  ];

export const ExportIncotermsType = {
  DAP: "DAP",
  DDP: "DDP",
  CIF: "CIF",
  CFR: "CFR",
  CPT: "CPT",
  CIP: "CIP",
} as const;
export type ExportIncotermsString =
  (typeof ExportIncotermsType)[keyof typeof ExportIncotermsType];
export const EXPORT_INCOTERMS_SELECT_ITEMS: SelectItem<ExportIncotermsString>[] =
  [
    { value: ExportIncotermsType.DAP, text: "DAP(DDU)" },
    { value: ExportIncotermsType.DDP, text: "DDP" },
    { value: ExportIncotermsType.CIF, text: "CIF" },
    { value: ExportIncotermsType.CFR, text: "CFR" },
    { value: ExportIncotermsType.CPT, text: "CPT" },
    { value: ExportIncotermsType.CIP, text: "CIP" },
  ];

export const ExportCustomsClearanceType = {
  GENERAL: "G",
  RETURN_CARGO: "RC",
  CARNET: "CARNET",
  CITES: "CITES",
  CNEE: "CNEE",
} as const;
export type ExportCustomsClearanceTypeString =
  (typeof ExportCustomsClearanceType)[keyof typeof ExportCustomsClearanceType];
export const EXPORT_CUSTOMS_SELECT_ITEMS: SelectItem<ExportCustomsClearanceTypeString>[] =
  [
    { value: ExportCustomsClearanceType.GENERAL, text: "GENERAL" },
    {
      value: ExportCustomsClearanceType.RETURN_CARGO,
      text: "RETURN CARGO (REPAIR, TEST ETC)",
    },
    { value: ExportCustomsClearanceType.CARNET, text: "CARNET" },
    { value: ExportCustomsClearanceType.CITES, text: "CITES" },
    { value: ExportCustomsClearanceType.CNEE, text: "CNEE" },
  ];

export const ExportTypeOfCargoType = {
  D: "D",
  REFRIGERATED: "REFRIGERATED",
  COSMETICS: "COSMETICS",
  FOOD: "FOOD",
} as const;
export type ExportOfCargoString =
  (typeof ExportTypeOfCargoType)[keyof typeof ExportTypeOfCargoType];
export const EXPORT_TYPE_OF_CARGO_SELECT_ITEMS: SelectItem<ExportOfCargoString>[] =
  [
    { value: ExportTypeOfCargoType.D, text: "DG" },
    { value: ExportTypeOfCargoType.REFRIGERATED, text: "REFRIGERATED" },
    { value: ExportTypeOfCargoType.COSMETICS, text: "COSMETICS" },
    { value: ExportTypeOfCargoType.FOOD, text: "FOOD" },
  ];

export const AirTemperatureRangeType = {
  TEMPERATURE_LV_1: "TEMPERATURE_LV_1",
  TEMPERATURE_LV_2: "TEMPERATURE_LV_2",
  FROZEN: "FROZEN",
  UNKNOWN: "UNKNOWN",
} as const;
export type AirTemperatureRangeString =
  (typeof AirTemperatureRangeType)[keyof typeof AirTemperatureRangeType];
export const AIR_TEMPERATURE_RANGE_SELECT_ITEMS: SelectItem<AirTemperatureRangeString>[] =
  [
    { value: AirTemperatureRangeType.TEMPERATURE_LV_1, text: "2 ~ 8℃" },
    {
      value: AirTemperatureRangeType.TEMPERATURE_LV_2,
      text: "15 ~ 25℃",
    },
    { value: AirTemperatureRangeType.FROZEN, text: "FROZEN" },
  ];
