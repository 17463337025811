import { defineStore } from "pinia";
import type { LanguageTypeString } from "@/definitions/selections";
import { LanguageType } from "@/definitions/selections";

interface Language {
  language: LanguageTypeString;
}

export const useLanguageStore = defineStore("language", {
  state: (): Language => {
    return {
      language: LanguageType.ENGLISH,
    };
  },
  actions: {},
  persist: true,
});
